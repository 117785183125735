import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{staticStyle:{"background":"#eaeaea"}},[(_vm.IS_CUSTOMER_PORTAL > -1)?[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"min-height":"100vh"}},[(_vm.IS_CUSTOMER_PORTAL)?[_c('div',[_c('header',{attrs:{"id":"page-header"}},[_c('div',{staticClass:"header-top",staticStyle:{"background":"'#33691E'"}}),_c('div',{staticClass:"header-bottom"},[_c('PortalUserMenu')],1)])])]:_vm._e(),(_vm.loggedIn)?_c('div',{staticClass:"d-flex",staticStyle:{"height":"100%"}},[(_vm.shouldShowCustomerPortal)?_c('Impersonator',{staticStyle:{"position":"absolute","top":"0","right":"0","height":"auto","z-index":"1000"}}):_vm._e(),(_vm.me && !_vm.shouldShowCustomerPortal)?_c('EmployeeLeftNav',{attrs:{"showNavDrawer":_vm.showNavDrawer}}):_vm._e(),(_vm.showNavigation && !_vm.IS_CUSTOMER_PORTAL)?_c(VAppBar,{attrs:{"flat":"","short":"","app":!_vm.IS_CUSTOMER_PORTAL,"color":"dark"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c(VAppBarNavIcon,{staticClass:"white--text",staticStyle:{"margin-left":"1px"},attrs:{"data-testid":"navDrawerMenu"},on:{"click":function($event){$event.stopPropagation();_vm.showNavDrawer = !_vm.showNavDrawer}}}):_vm._e(),_c('HeaderSearch'),_c(VMenu,{attrs:{"tile":"","offset-y":"","max-width":_vm.$vuetify.breakpoint.mobile ? "100%" : 360,"max-height":_vm.$vuetify.breakpoint.mobile ? "92vh" : "50vh","min-width":"360","nudge-left":_vm.$vuetify.breakpoint.mobile ? 0 : ""},on:{"input":_vm.onMenuInput},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"toolbar-button h-100",attrs:{"icon":"","dark":"","tile":""}},on),[(_vm.notificationsBadgeCount)?_c(VBadge,{attrs:{"content":_vm.notificationsBadgeCount,"color":"primary","overlap":""}},[_c(VIcon,{staticStyle:{"color":"white !important"}},[_vm._v("mdi-bell-outline")])],1):_c(VIcon,[_vm._v("mdi-bell-outline")])],1)]}}],null,false,811493026)},[_c(VToolbar,{staticClass:"pr-0",attrs:{"color":"secondary_medium","dark":"","flat":"","height":"36"}},[_c(VToolbarTitle,{staticClass:"subtitle-1 font-weight-medium"},[_vm._v("Notifications")]),_c(VSpacer),(_vm.notifications.length)?_c(VBtn,{attrs:{"text":""},on:{"click":_vm.clearAllNotifications}},[_vm._v("Clear All")]):_vm._e()],1),(_vm.notifications.length)?_c(VList,{staticClass:"pb-0 white",staticStyle:{"overflowy":"scroll"},attrs:{"three-line":"","subheader":""}},_vm._l((_vm.notifications),function(notification,i){return _c(VExpandTransition,{key:notification.id},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VListItem,{staticStyle:{"min-height":"72px"},style:({
                        background: notification.status
                          ? "primary_background"
                          : notification.icon == "task"
                          ? "warning_background"
                          : "white",
                      }),attrs:{"to":notification.permalink}},[_c('RecordIcon',{staticClass:"mr-2",attrs:{"record":notification.icon,"avatar-props":{ size: 40 }}}),_c(VListItemContent,{class:{
                          'font-weight-medium': notification.status == 1,
                        }},[_c(VListItemTitle,{staticStyle:{"whitespace":"unset !important"},domProps:{"innerHTML":_vm._s(notification.title)}}),_c(VListItemSubtitle,{staticClass:"task-description",domProps:{"innerHTML":_vm._s(notification.description)}}),_c('div',{staticClass:"body-2 primary--text",class:{
                            'font-weight-medium': notification.status == 1,
                          }},[_vm._v(" "+_vm._s(["subtitle_record_type", "record_name"] .map(function (field) { return notification[field]; }) .filter(function (val) { return val; }) .join(" • "))+" ")])],1),_c(VListItemAction,{staticClass:"text-right caption align-self-start",class:!notification.status && notification.icon == "task"
                            ? "warning--text"
                            : "text--secondary"},[_c(VBtn,{staticStyle:{"transition":"opacity 0.3s","position":"absolute"},style:(hover || { opacity: 0 }),attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.clearNotification(i)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c('div',{staticStyle:{"transition":"opacity 0.3s"},style:(hover && { opacity: 0, pointerEvents: "none" })},[_c('span',{staticClass:"text-no-wrap"},[_c('span',{staticStyle:{"vertical-align":"middle"}},[_vm._v(" "+_vm._s(_vm.dayjs().diff(notification.date_created, "m") ? ["Today", "Yesterday"].find( function (label, difference) { return [ _vm.dayjs(notification.date_created), _vm.dayjs().subtract(difference, "d") ] .map(function (date) { return date.format("YYYYMMDD"); } ) .reduce(function (a, b) { return a == b; }); } ) || _vm.formatDateTime( "MMMM D", notification.date_created ) : "Now")+" ")]),(notification.status)?_c(VIcon,{staticStyle:{"width":"12px"},attrs:{"color":"primary"}},[_vm._v("mdi-circle-medium")]):_vm._e()],1),(notification.icon == "task")?[_c('br'),_vm._v(_vm._s(_vm.formatDateTime( "h:mm A", notification.date_created ))+" ")]:_vm._e()],2)],1)],1)]}}],null,true)})],1)}),1):_c('div',{staticClass:"py-8 text-center subtitle-1 text--disabled white"},[_vm._v(" No notifications ")])],1),_c(VMenu,{attrs:{"tile":"","offset-y":"","left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"toolbar-button h-100 d-none d-sm-flex",attrs:{"icon":"","dark":"","tile":""}},on),[_c(VIcon,[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,false,3297809039)},[_c(VList,{staticClass:"py-0",attrs:{"width":"250"}},_vm._l((_vm.newRecordTypeOptions.filter(
                    function (ref) {
                                    var type = ref.type;
                                    var showInMenu = ref.showInMenu;

                                    return !type.startsWith("quick") && showInMenu;
}
                  )),function(ref){
                  var pascalType = ref.pascalType;
                  var type = ref.type;
return _c(VListItem,{key:type,on:{"click":function($event){return _vm.setNewRecordOpen(pascalType, true)}}},[_c('RecordIcon',{staticClass:"mr-1",attrs:{"avatar-props":{ size: 32 },"icon-props":{ size: "medium" },"record":type}}),_c(VListItemTitle,[_vm._v("New "+_vm._s(pascalType))])],1)}),1)],1),_c(VMenu,{attrs:{"tile":"","offset-y":"","left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"toolbar-button h-100",attrs:{"dark":"","large":"","color":"transparent","elevation":"0","tile":""}},on),[_c('Avatar',{attrs:{"name":_vm.name,"img-src":_vm.me.profile_picture_key}}),_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2118399767)},[_c(VList,{staticClass:"py-0",attrs:{"width":"250"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,{attrs:{"size":32}},[_c('Avatar',{attrs:{"name":_vm.name,"img-src":_vm.me.profile_picture_key,"size":32}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"font-weight":"500","line-height":"20px"}},[_vm._v(_vm._s(_vm.name))]),_c(VListItemSubtitle,{staticStyle:{"letter-spacing":"0.4px"}},[_vm._v(" "+_vm._s(_vm.UserGroupById(_vm.me.user_group_id).name)+" ")])],1)],1),_c(VDivider),_vm._l((_vm.topRightMenu),function(item,title){return _c(VListItem,{key:title,on:{"click":item.click}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-"+_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(title))])],1)})],2)],1),_c('Impersonator',{staticClass:"flex-grow-0 impersonator-input d-none d-sm-flex"})],1):_vm._e(),_c(VMain,[(!_vm.IS_CUSTOMER_PORTAL && _vm.showNavigation)?_c('HeaderAlert'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasConfigBeenFetched)?_c('router-view',{ref:"routerView"}):_c(VContainer,[_c(VSkeletonLoader,{attrs:{"data-testid":"appLoading","loading":"","type":"table"}})],1)],1)],1),(_vm.showNavigation)?[_vm._l((_vm.newRecordTypeOptions.filter(
                function (ref) {
                              var type = ref.type;

                              return _vm.newRecordTypes[type].open;
}
              )),function(type){return _c(type.component,_vm._b({key:type.type,tag:"component",on:{"hidden":function($event){return _vm.setNewRecordOpen(type.pascalType, false)}}},'component',_vm.newRecordTypes[type.type].props,false))}),_vm._l((_vm.modals),function(modal,i){return _c(modal.component,{key:i,tag:"component"})}),_c(VSnackbar,{attrs:{"light":"","timeout":"-1"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"href":_vm.$route.path,"icon":""}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c(VBtn,_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showNewVersionSnackbar = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}],null,false,2410499935),model:{value:(_vm.showNewVersionSnackbar),callback:function ($$v) {_vm.showNewVersionSnackbar=$$v},expression:"showNewVersionSnackbar"}},[_vm._v(" Looks like a new version of Farmgate just got released! Refresh your page to get all the latest updates. ")])]:_vm._e(),_c(VDialog,{attrs:{"value":!!_vm.popup,"width":_vm.popup && _vm.popup.customWidth
                ? _vm.popup.customWidth
                : _vm.popup && _vm.popup.prompt
                ? 566
                : 280},on:{"input":_vm.hidePopup}},[(_vm.popup)?_c(VCard,[(_vm.popup.title)?_c(VCardTitle,{class:_vm.popup.titleClass,domProps:{"innerHTML":_vm._s(_vm.popup.title)}}):_vm._e(),_c(VCardText,{attrs:{"data-testid":"popupText"}},[_c('div',{class:[_vm.popup.messageClass, _vm.popup.alert && "pt-6"],domProps:{"innerHTML":_vm._s(_vm.popup.message)}}),(_vm.popup.prompt)?_c('FormInput',{staticClass:"pt-6 pa-0",attrs:{"label":_vm.popup.prompt,"maxlength":_vm.popup.maxLength},model:{value:(_vm.popupInput),callback:function ($$v) {_vm.popupInput=$$v},expression:"popupInput"}}):_vm._e()],1),_c(VCardActions,{class:{
                  'justify-end': _vm.popup.prompt,
                  'd-flex justify-end': _vm.popup.btnAlignRight,
                }},_vm._l((Object.entries({
                    no: "dark",
                    yes: _vm.popup.yesBtnColor,
                  })
                    .map(function (ref) {
                                  var btn = ref[0];
                                  var color = ref[1];

                                  return ({ btn: btn, color: color, text: _vm.popup[btn] });
})
                    .filter(function (ref) {
                                  var text = ref.text;

                                  return text;
})),function(ref,yes){
                    var btn = ref.btn;
                    var color = ref.color;
                    var text = ref.text;
return _c(VBtn,{key:btn,style:(_vm.popup.btnHeight && { height: ((_vm.popup.btnHeight) + "px") }),attrs:{"color":!!yes && _vm.popup.yesBtnColor ? _vm.popup.yesBtnColor : color,"disabled":!!yes && _vm.popup.prompt && !_vm.popupInput},on:{"click":function($event){_vm.popup.resolve(_vm.popup.prompt && yes ? _vm.popupInput : !!yes);
                    _vm.hidePopup();}}},[_vm._v(" "+_vm._s(text)+" ")])}),1)],1):_vm._e()],1)],2):_c('div',{staticClass:"d-flex flex-column align-center",staticStyle:{"margin":"120px 0"}},[(!_vm.IS_CUSTOMER_PORTAL)?_c('PublicImage',{staticClass:"mx-auto mt-auto",attrs:{"src":"FG Light Horizontal"}}):_vm._e(),(_vm.showCustomerPortalUserError)?_c(VAlert,{attrs:{"color":"error","text":""}},[_vm._v(" It looks like you are attempting to log in with a user that does not have access to Customer Portal. Please create a new Customer Portal user by clicking the link below. ")]):_vm._e(),_c('amplify-authenticator',{ref:"authenticator",staticClass:"mx-4 rounded",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.24)","--amplify-background-color":"var(--v-light-base)","--amplify-font-family":"Roboto, sans-serif","--amplify-primary-color":"var(--v-primary-base)","--amplify-text-md-sub":"24px","--box-shadow":"none","--container-height":"0","--header-size":"40px","--margin-bottom":"0","--padding":"24px 24px 30px","--width":"330px"}},[_c('amplify-sign-in',{attrs:{"slot":"sign-in","header-text":_vm.IS_CUSTOMER_PORTAL
                  ? "Customer Portal Login"
                  : "Sign in to Farmgate","hide-sign-up":"","submit-button-text":"Login"},slot:"sign-in"})],1),(_vm.IS_CUSTOMER_PORTAL)?[_c('p',{staticClass:"create-account"},[_vm._v(" Don't have an account yet? "),_c('a',{on:{"click":function($event){$event.stopPropagation();_vm.showCreateAccount = true}}},[_vm._v("Click here to create your account.")])]),_c('CreateAccountInitModal',{attrs:{"is-active":_vm.showCreateAccount}})]:_vm._e()],2),(_vm.IS_CUSTOMER_PORTAL)?_c(VAlert,{staticClass:"mt-auto mb-0",attrs:{"info":"primary","text":"","rounded":"0"}},[_vm._v(" For any questions about your account or to get help with technical issues, email "),_c('Mailto',{attrs:{"email":"help@advancedagrilytics.com"}}),_vm._v(". ")],1):_vm._e()],2),_c('TaskCloseConfirmationModal')]:_c(VAlert,{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"color":"error","text":""}},[_vm._v(" This is not a supported domain for accessing Farmgate. ")]),(_vm.IS_QA)?_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"position":"fixed","z-index":"10","opacity":"0.5","pointer-events":"none","bottom":"0","width":"100vw"}},_vm._l((4),function(i){return _c('div',{key:i,staticClass:"text-center",staticStyle:{"background":"red","color":"white"}},[_vm._v(" THIS IS THE QA ENVIRONMENT."),_c('br'),_vm._v("THIS SITE IS FOR TESTING PURPOSES ONLY. ")])}),0):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
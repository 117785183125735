import Vue from 'vue';
import VueRouter from 'vue-router';
import functions from '@/mixins/functions';
import Auth from '@aws-amplify/auth';
import DefaultView from '../components/DefaultView';

Vue.use(VueRouter);

const { CUSTOMER_PORTAL_PATH_PREFIX, IS_CUSTOMER_PORTAL } = functions.data(),
  getComponent = (view, type = `EMPLOYEE`) => {
    const isAccountingForm = type == `ACCOUNTING_FORM`;
    return () =>
      import(
        `../${
          isAccountingForm
            ? `components/forms/accounting`
            : `views/${type.toLowerCase()}`
        }/${view}${isAccountingForm ? `Form` : ``}`
      );
  },
  redirect = (path, redirect) => ({ path, redirect }),
  id = (name) => `/:${name}(\\d+)`;

const routes = [
  ...(IS_CUSTOMER_PORTAL
    ? [
        // Customer views
        ...Object.entries({
          CreateAccount: {
            path: `/*`,
            meta: { fullscreen: true, unprotected: true },
          },
          InvoicesBilling: { meta: {} },
          Home: { path: false, meta: {} },
          Agronomy: { meta: {} },
          MyAccount: { meta: {} },
        }).map(([component, { path = ``, meta }]) => {
          const componentName = component.replace(
            /(.?)([A-Z])/g,
            (match, a, b) => (a ? `${a}-` : ``) + b.toLowerCase()
          );
          return {
            name: componentName,
            meta: { ...meta, block: [] },
            path:
              `${CUSTOMER_PORTAL_PATH_PREFIX}/` +
              (path === false ? `` : componentName + path),
            component: getComponent(component, `CUSTOMER`),
            props: true,
          };
        }),

        {
          name: 'Invoices Billing',
          path: `${CUSTOMER_PORTAL_PATH_PREFIX}/invoices-billing/:id`,
          component: getComponent(`Invoice`, `ACCOUNTING_FORM`),
          meta: {},
          props: true,
        },

        {
          name: 'Logout',
          path: `${CUSTOMER_PORTAL_PATH_PREFIX}/logout`,
          async beforeEnter(to, from, next) {
            await Auth.signOut();
            next(CUSTOMER_PORTAL_PATH_PREFIX);
          },
        },
      ]
    : [
        // Employee views
        ...[
          {
            routes: {
              Target: {
                search: {
                  target_name: `name`,
                  operation_name: ``,
                  agronomist: `employee`,
                },
              },
              Operation: {
                search: {
                  name: ``,
                  city_and_state: ``,
                  county: ``,
                  total_acres: `acres`,
                  agronomist: `employee`,
                },
              },
              Contact: {
                search: {
                  contact_name: `name`,
                  city_and_state: ``,
                  county: ``,
                  agronomist: `employee`,
                },
              },
            },
            children: (type) => [
              redirect(``, `info`),
              ...Object.entries({
                info: `${type}Info`,
                contracts: `Contracts`,
                [`contracts${id(`contractId`)}`]: `Contract`,
                targets: `OperationTargets`,
                scouting: `Scouting`,
              }).map(([path, view]) => ({
                path,
                component: getComponent(view),
              })),
            ],
          },
          {
            noL2: true,
            routes: {
              Task: { search: { title: ``, related_to: `` } },
              BillingRecord: {
                firstWord: true,
                search: {},
              },
              Dashboard: { search: {}, noPluralize: true },
            },
          },
          {
            routes: {
              Account: {
                search: {
                  account_name: `contact`,
                  agronomist: `employee`,
                  operation_names: `operation`,
                  open_balance: `money`,
                },
                subRoutes: () =>
                  [
                    `credit-memo`,
                    `prepayment-discount`,
                    `promotional-discount`,
                  ].map((path) => ({
                    path: `${path}s/:creditId`,
                    component: getComponent(`Credit`, `ACCOUNTING_FORM`),
                    props: ({ params }) => ({ id: params.creditId }),
                  })),
              },
            },
          },
          {
            isAccountingForm: true,
            block: [`PA`, `ABM`],
            routes: {
              Invoice: {
                search: {
                  contact_name: `contact`,
                  formatted_number: ``,
                  status: {
                    type: `status`,
                    component: `TransactionStatusChip`,
                    status_id: `status_id`,
                  },
                  open_balance: `money`,
                },
              },
              Payment: {
                search: {
                  contact_name: `contact`,
                  date: `date`,
                  amount: `money`,
                  open_balance: `money`,
                  status: {
                    type: `status`,
                    component: `TransactionStatusChip`,
                    status_id: `status_id`,
                  },
                },
                searchRecord: `credits`,
              },
            },
          },
          {
            routes: {
              Scouting: { search: {}, noPluralize: true },
              SoilSampling: { search: {}, noPluralize: true },
            },
          },
        ].flatMap(
          ({ routes, children, block, isAccountingForm, noL2, ...rest }) =>
            Object.entries(routes).flatMap(
              ([
                type,
                { search, noPluralize, firstWord, searchRecord, subRoutes },
              ]) => {
                const meta = {
                    ...rest,
                    block: block || [],
                    searchFields: Object.fromEntries(
                      Object.entries(search).map(([key, type]) => [
                        key,
                        {
                          type: type.type || type,
                          searchable: ![
                            `employee`,
                            `acres`,
                            `status`,
                            `money`,
                            `date`,
                          ].includes(type.type || type),
                          ...(type.component && {
                            component: type.component,
                          }),
                          ...(type.status_id && {
                            status_id: type.status_id,
                          }),
                        },
                      ])
                    ),
                    ...(searchRecord && { searchRecord }),
                  },
                  typeFirstWord = /^.[a-z]+/.exec(type),
                  l1 =
                    (firstWord ? typeFirstWord : type) +
                    (noPluralize || firstWord ? `` : `s`),
                  path = `/${l1
                    .replace(/.[A-Z]/, (string) => `${string[0]}-${string[1]}`)
                    .toLowerCase()}`,
                  propsAndMeta = { props: true, meta },
                  l2Path = path + id(`id`),
                  routeData = [
                    {
                      name: l1,
                      path,
                      component: getComponent(l1),
                      meta,
                    },
                    ...(noL2
                      ? []
                      : [
                          {
                            path: l2Path,
                            component: getComponent(
                              typeFirstWord,
                              isAccountingForm ? `ACCOUNTING_FORM` : `EMPLOYEE`
                            ),
                            ...propsAndMeta,
                            ...(children && {
                              children: children(type).map((route) => ({
                                ...(!route.redirect && propsAndMeta),
                                ...route,
                              })),
                            }),
                          },
                        ]),
                    ...(subRoutes
                      ? subRoutes().map((route) => ({
                          ...propsAndMeta,
                          ...route,
                          path: `${l2Path}/${route.path}`,
                        }))
                      : []),
                  ];
                return routeData;
              }
            )
        ),
        {
          name: 'Acre Report',
          path: '/acrechangereport',
          component: getComponent('AcreReport'),
          props: (route) => ({
            ...route.params,
          }),
        },
        {
          name: 'Task Details',
          path: '/tasks/:taskId',
          component: getComponent('Tasks'),
          props: true,
          meta: {
            searchFields: {
              title: { type: ``, searchable: true },
              related_to: { type: ``, searchable: true },
            },
          },
        },
        {
          name: 'Nutrient Sign',
          path: '/nutrient/sign/:signerId/:signerUuid',
          component: getComponent('SowSign', 'CUSTOMER'),
          props: (route) => ({
            ...route.params,
            sentUuid: route.query.emailId,
            type: 'nutrient',
          }),
          meta: { unprotected: true },
        },
        {
          name: 'Sow Sign',
          path: '/sow/sign/:signerId/:signerUuid',
          component: getComponent('SowSign', 'CUSTOMER'),
          props: (route) => ({
            ...route.params,
            sentUuid: route.query.emailId,
            type: 'sow',
          }),
          meta: { unprotected: true },
        },
        {
          name: 'Sign',
          path: `/sign/:signerId/:signerUuid/:sentUuid`,
          component: getComponent(`Sign`, `CUSTOMER`),
          props: true,
          meta: { unprotected: true },
        },

        {
          name: 'Blank Puppeteer',
          path: `/puppeteer/blank/:idAndUuid+`,
          component: getComponent(`Puppeteer`),
          props: ({ params }) => ({ ...params, blank: true }),
          meta: { fullscreen: true, unprotected: true },
        },

        {
          name: 'Puppeteer',
          path: `/puppeteer/:idAndUuid+`,
          component: getComponent(`Puppeteer`),
          props: true,
          meta: { fullscreen: true, unprotected: true },
        },

        {
          name: 'Print Invoices',
          path: `/invoices/print/:invoiceIds`,
          component: getComponent(`PrintInvoices`),
          meta: { fullscreen: true },
          props: true,
        },
      ]),
  { path: '/', component: DefaultView },
  { name: 'Not Found', path: `*`, component: getComponent(`NotFound`) },
];

export default new VueRouter({
  mode: `history`,
  base: process.env.BASE_URL,
  routes: routes,
});

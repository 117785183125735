<template>
  <v-container fluid>
    <v-row justify="space-between" align="center">
      <v-col>
        <PublicImage src="AA Light Horizontal" style="max-height: 56px" />
      </v-col>
      <v-col>
        <div class="d-flex justify-end">
          <v-menu v-if="me" bottom rounded offset-y>
            <template #activator="{ on }">
              <v-btn icon x-large v-on="on">
                <v-avatar color="primary_medium" size="48">
                  <span class="white--text text-h5">{{ initials }}</span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div
                  class="avatar-wrapper d-flex justify-center align-center px-5"
                >
                  <div class="avatar-icon">
                    <v-avatar color="primary_medium">
                      <span class="white--text text-h5">{{ initials }}</span>
                    </v-avatar>
                  </div>
                  <div
                    class="
                      avatar-content
                      d-flex
                      flex-column
                      align-self-center
                      flex-grow-1
                      ml-2
                    "
                  >
                    <div>
                      <h3>{{ fullName }}</h3>
                      <p class="text-caption ma-0">
                        {{ user.email }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="menu-nav">
                  <div>
                    <v-btn href="/customer/my-account" plain text>
                      <v-icon class="mr-2">mdi-account-circle</v-icon>
                      My Account
                    </v-btn>
                  </div>
                  <div>
                    <v-btn href="/customer/logout" plain text>
                      <v-icon class="mr-2">mdi-door-open</v-icon>
                      Logout
                    </v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PublicImage from '@/components/misc/PublicImage';
import { mapGetters } from 'vuex';
export default {
  name: `PortalUserMenu`,
  components: { PublicImage },
  data() {
    return {
      user: {
        first_name: 'John',
        last_name: 'Doe',
        email: 'johndoe@advancedagrilytics.com',
      },
    };
  },
  computed: {
    ...mapGetters(`auth`, [`me`]),
    initials() {
      if (!this.user.first_name || !this.user.last_name) {
        return '--';
      }
      return (
        this.user.first_name.charAt(0).toUpperCase() +
        this.user.last_name.charAt(0).toUpperCase()
      );
    },
    fullName() {
      return this.user.first_name + ' ' + this.user.last_name;
    },
  },
  watch: {
    me: {
      handler(new_val, old) {
        if (!new_val) {
          return;
        }
        const { first_name, last_name, email } = new_val;
        this.user = Object.assign({}, this.user, {
          first_name,
          last_name,
          email,
        });
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-nav {
  margin-top: 1rem;
  padding-left: 5px;
  div {
    padding: 0.2rem 0;
  }
}
</style>

import { get } from '@/services/api-service';

export default {
  namespaced: true,

  state: {
    loading: false,
    operationTargets: [],
  },

  actions: {
    async loadOperationTargets({ commit, dispatch }, operationId) {
      commit('updateOperationTargets', { loading: true, operationTargets: [] });

      try {
        const operationTargets = await get(
          `targets/${operationId}/operation-targets`
        );
        commit('updateOperationTargets', { loading: false, operationTargets });
      } catch (e) {
        dispatch('app/showError', 'Error retrieving operation targets data', {
          root: true,
        });
        commit('updateOperationTargets', { loading: false });
      }
    },
  },

  mutations: {
    updateOperationTargets(state, payload) {
      Object.assign(state, payload);
    },
  },
};

import { post } from '../services/api-service';

export default {
  namespaced: true,

  state: {
    saving: false,
  },

  actions: {
    async saveNewTarget({ commit, dispatch }, targetDetails) {
      commit('updateTarget', { saving: true });

      try {
        await post(`targets/new`, targetDetails);

        commit('updateTarget', { saving: false });

        dispatch('app/showSuccess', 'New Target created!', {
          root: true,
        });
      } catch (e) {
        dispatch('app/showError', 'Error saving new target', {
          root: true,
        });
        commit('updateTarget', { saving: false });
      }
    },
  },

  mutations: {
    updateTarget(state, payload) {
      Object.assign(state, payload);
    },
  },
};

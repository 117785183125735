<template>
  <tr
    :class="{ gray: highlightedRow == index }"
    style="cursor: pointer"
    @click="$emit('legacyClick', item)"
  >
    <td
      v-for="({ type, searchable, component }, col) in $route.meta.searchFields"
      :key="col"
    >
      <div
        :class="`d-flex flex-row align-center ${
          type === `money` && `justify-end`
        }`"
      >
        <Highlighter v-if="searchable" :value="item[col]" :highlight="input" />
        <div v-else-if="type == `employee`">
          <div>{{ item[col] }}</div>
          <div class="caption">
            {{ UserGroupById(UserById(item[`${col}_id`]).user_group_id).name }}
          </div>
        </div>
        <template v-else-if="type == `acres`">
          {{ item[col].toLocaleString() }} acre{{ xs(item[col]) }}
        </template>
        <template v-else-if="type == `status`">
          <component :is="component" v-if="component" :item="item" small />
          <v-chip v-else>{{ item[col] }}</v-chip>
        </template>
        <template v-else-if="type == `money`">
          {{ formatUSD(item[col]) }}
        </template>
        <template v-else-if="type == `date`">
          {{ formatDateTime(`M-DD-YYYY`, item[col]) }}
        </template>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import functions from '@/mixins/functions';
import Highlighter from '@/components/Highlighter';

export default {
  name: 'LegacySearchResults',

  components: {
    Highlighter,
  },

  props: {
    input: String,
    item: Object,
    index: Number,
    highlightedRow: Number,
  },

  mixins: [functions],

  computed: {
    ...mapGetters('crm', ['UserById', 'UserGroupById']),
  },
};
</script>

import { post, authorizedGetUrl } from '@/services/api-service';

const removeShape = (field) => ({
  ...field,
  shape: undefined,
});

const streamlineBillingGroups = (billingGroups) =>
  billingGroups.map((billingGroup) => ({
    ...billingGroup,
    fields: billingGroup.fields.map(removeShape),
    soilFields: billingGroup.soilFields.map(removeShape),
  }));

export async function saveBillingGroups(
  { commit, state, getters },
  { contractId, targetId }
) {
  try {
    const {
      billingGroups,
      firstBillingMonth,
      rotationSchedule,
      additionalBillingInfo,
      addresses,
    } = state;

    commit('updateBillingGroups', { saving: true });

    await post(`sow/${contractId}/billing`, {
      additionalBillingInfo,
      billingCycle: getters.billingCycle,
      billingGroups: streamlineBillingGroups(billingGroups),
      firstBillingMonth,
      rotationSchedule,
      targetId,
      addresses,
    });

    const pdfUrl = await authorizedGetUrl(`sow/billing/pdf/${contractId}`);
    commit('updateBillingGroups', { saving: false, pdfUrl });
  } catch (e) {
    commit('updateBillingGroups', { saving: false, showError: true });
  }
}

import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"dark":"","color":"dark","width":"208px","app":true,"value":_vm.showNavDrawer}},[_c('PublicImage',{staticStyle:{"max-height":"56px"},attrs:{"src":"Dark Horizontal"}}),_c(VList,{staticClass:"pt-0",attrs:{"shaped":"","dense":""}},[_vm._l((_vm.navigationItems),function(item,i){return [(item.children.length > 0)?_c(VListGroup,{key:i,attrs:{"value":"true","no-action":"","append-icon":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemAvatar,{attrs:{"size":"small","rounded":"0"}},[_c(VIcon,[_vm._v("mdi-"+_vm._s(item.icon))])],1),_c(VListItemTitle,{attrs:{"data-testid":"nav-items"}},[_vm._v(_vm._s(item.name)+" ")])]},proxy:true}],null,true)},_vm._l((item.children),function(child){return _c('NavItem',{key:child.name,staticClass:"pl-9",attrs:{"data-testid":"nav-items","item":child}})}),1):_c('NavItem',{key:i,attrs:{"data-testid":"nav-items","item":item,"exact":""}})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
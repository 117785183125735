var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{domProps:{"innerHTML":_vm._s(
    _vm.value
      ? _vm.value.replace(
          new RegExp(
            _vm.highlight.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"),
            "gi"
          ),
          "<mark>$&</mark>"
        )
      : ''
  )}})}
var staticRenderFns = []

export { render, staticRenderFns }
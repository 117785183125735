import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import transform from 'lodash/transform';
import { get, post, put } from '../services/api-service';

export default {
  namespaced: true,

  state: {
    showFlagModal: false,
    flaggedBillingRecord: undefined,
    archiveBillingRecordId: 0,
    loading: false,
    billingRecords: [],
    selectedTab: undefined,
    approvingIds: [],
    pendingApprovals: [],
    isArchiving: false,
  },

  getters: {
    showArchiveModal: ({ archiveBillingRecordId }) => {
      return archiveBillingRecordId !== 0;
    },
    activeBillingRecords: (
      { selectedTab, billingRecords },
      { getBillingGroups }
    ) => {
      if (selectedTab && selectedTab.title !== 'All') {
        return getBillingGroups[selectedTab.title];
      }

      return billingRecords;
    },
    getBillingGroups: ({ billingRecords }) => {
      const groups = groupBy(billingRecords, 'status');

      return transform(
        groups,
        (acc, records, status) => {
          acc[status] = records;
        },
        {}
      );
    },
  },

  actions: {
    setTab({ commit }, tab) {
      commit('setTab', tab);
    },

    async closeArchiveModal({ commit }) {
      commit('setArchiveBillingRecord', 0);
      commit('setArchiving', false);
    },

    async setArchiveBillingRecord({ commit }, billingId) {
      commit('setArchiveBillingRecord', billingId);
    },

    async archiveBilling({ commit, dispatch, state }) {
      try {
        commit('setArchiving', true);

        await put(`crud/billing-record/${state.archiveBillingRecordId}`, {
          is_archived: 1,
        });
      } catch (e) {
        dispatch('app/showError', 'Error archiving billing record', {
          root: true,
        });
      }
    },

    async loadBilling({ commit, dispatch }) {
      commit('updateLoading', true);

      try {
        const billingRecords = await get('billing');

        commit('updateBillingRecords', billingRecords);
      } catch (e) {
        dispatch('app/showError', 'Error retrieving billing data', {
          root: true,
        });
      }

      commit('updateLoading', false);
    },

    async setApproval(context, { billingRecordIds, acreDiscrepancyReason }) {
      await setApprovalStatus(context, billingRecordIds, () =>
        post('billing/approve', { billingRecordIds, acreDiscrepancyReason })
      );
    },

    async setUnApproval(context, billingRecordId) {
      await setApprovalStatus(context, [billingRecordId], () =>
        post(`billing/${billingRecordId}/unapprove`)
      );
    },

    setFlaggedBilling({ commit }, flaggedBillingRecord) {
      commit('flaggedBillingRecord', {
        flaggedBillingRecord,
        showFlagModal: flaggedBillingRecord !== undefined,
      });
    },
  },

  mutations: {
    setArchiving(state, isArchiving) {
      state.isArchiving = isArchiving;
    },
    setArchiveBillingRecord(state, billingRecordId) {
      state.archiveBillingRecordId = billingRecordId;
    },
    setPendingApprovals(state, ids) {
      state.pendingApprovals = [...ids];
    },
    updatedRecords(state, results) {
      const { pendingApprovals, approvingIds, billingRecords } = state;

      const isProcessed = (id) => !find(results, { id });
      state.approvingIds = approvingIds.filter(isProcessed);
      state.pendingApprovals = pendingApprovals.filter(isProcessed);

      state.billingRecords = billingRecords.map((billingRecord) => ({
        ...billingRecord,
        ...(find(results, { id: billingRecord.id }) || {}),
      }));
    },
    processApproval(state, billingRecordIds) {
      state.approvingIds.push(...billingRecordIds);
    },
    processedApproval({ approvingIds }, billingRecordIds) {
      approvingIds = approvingIds.filter(
        (id) => !billingRecordIds.includes(id)
      );
    },
    setTab(state, selectedTab) {
      state.selectedTab = selectedTab;
    },
    updateLoading(state, loading) {
      state.loading = loading;
    },
    updateBillingRecords(state, billingRecords) {
      state.loading = false;
      state.billingRecords = billingRecords;
    },
    flaggedBillingRecord(state, { flaggedBillingRecord, showFlagModal }) {
      state.flaggedBillingRecord = flaggedBillingRecord;
      state.showFlagModal = showFlagModal;
    },
  },
};

async function setApprovalStatus(context, billingRecordIds, approvalUpdate) {
  const { dispatch, commit } = context;

  try {
    commit('processApproval', billingRecordIds);

    const results = await approvalUpdate();

    commit('updatedRecords', results);
  } catch (e) {
    commit('processedApproval', billingRecordIds);
    dispatch('app/showError', 'Error setting approval status', {
      root: true,
    });
  }
}

const getBasic = (quick) => ({ quick, showInMenu: true, newPrefix: true }),
  contactAndOperation = getBasic(true),
  newRecordsEntries = Object.entries({
    Target: getBasic(),
    Task: { quick: false, showInMenu: true },
    Operation: contactAndOperation,
    Contact: contactAndOperation,
    ...Object.fromEntries(
      [`Invoice`, `Payment`].map((type) => [type, { accounting: true }])
    ),
  }),
  newRecordTypes = newRecordsEntries.flatMap(([key, options]) =>
    [
      key[0].toLowerCase() + key.slice(1),
      ...(options.quick ? [`quick${key}`] : []),
    ].map((type) => [type, options])
  );

export default {
  namespaced: true,

  state: {
    breadcrumb: ``,
    popup: null,
    snackbar: { show: false, content: ``, color: `primary` },
    newRecordTypes: Object.fromEntries(
      newRecordTypes.map(([key, options]) => [
        key,
        {
          ...options,
          open: false && process.env.VUE_APP_STAGE == `dev` && key == `payment`,
        },
      ])
    ),
    DEBUG_CONTRACTS: process.env.VUE_APP_STAGE == `dev` && false,
  },

  mutations: {
    popup(state, popup) {
      state.popup = popup
        ? Object.assign({ yesBtnColor: 'primary' }, popup)
        : null;
    },
    snackbar(state, snackbar) {
      state.snackbar = snackbar;
    },
    ...Object.fromEntries(
      newRecordTypes.map(([key]) => [
        `setNew${key[0].toUpperCase() + key.slice(1)}Open`,
        ({ newRecordTypes }, value) =>
          (newRecordTypes[key] = {
            props: null,
            ...newRecordTypes[key],
            ...value,
          }),
      ])
    ),
    setBreadcrumb(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
  },

  actions: {
    showError({ commit }, content) {
      commit('snackbar', {
        show: true,
        content,
        isError: true,
      });
    },
    showSuccess({ commit }, content) {
      commit('snackbar', {
        show: true,
        color: 'success',
        content,
      });
    },
    ...Object.fromEntries(
      newRecordsEntries
        .filter(([, { quick }]) => quick)
        .map(([key]) => [
          `quickCreateNew${key}`,
          ({ commit }) =>
            new Promise((resolve) =>
              commit(`setNewQuick${key}Open`, {
                props: null,
                open: true,
                resolve,
              })
            ),
        ])
    ),
  },
};

export function addBillingGroup(
  { state, commit },
  { billingGroup, editIndex }
) {
  const billingGroups = [...state.billingGroups];

  if (editIndex !== -1) {
    billingGroups[editIndex] = billingGroup;
  } else {
    billingGroups.push({
      ...billingGroup,
      fields: [],
      soilFields: [],
    });
  }

  commit('updateBillingGroups', { billingGroups });
}

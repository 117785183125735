<template>
  <v-menu
    v-model="show"
    offset-y
    disable-keys
    :close-on-click="false"
    :close-on-content-click="false"
    :open-on-click="false"
  >
    <template #activator="{}">
      <v-form
        autocomplete="off"
        style="height: 100%"
        class="flex-grow-1"
        @submit.prevent="openSearchResult"
      >
        <v-text-field
          v-if="
            searchRecord && Object.keys($route.meta.searchFields || {}).length
          "
          data-testid="search"
          v-model="input"
          v-click-outside="searchClickOutside"
          background-color="secondary_medium"
          class="rounded-0 search-input"
          clearable
          dark
          flat
          hide-details
          :loading="!!request"
          :placeholder="`Search ${toTitleCase(searchRecord)}`"
          prepend-inner-icon="mdi-magnify"
          @focus="show = true"
          @click="show = true"
          @keydown.arrow-up.prevent="highlightedRow && highlightedRow--"
          @keydown.arrow-down.prevent="
            highlightedRow + 1 < items.length && highlightedRow++
          "
        />
      </v-form>
    </template>
    <div class="pl-5 mt-3 mb-3 text-h7" v-if="isLoading">Searching...</div>
    <div class="pl-5 mt-3 mb-3 text-h7" v-if="!input">Search Records</div>
    <div class="pl-5 mt-3 mb-3 text-h7" v-if="isNoResults">No Results</div>

    <v-simple-table
      v-if="items.length && input"
      data-testid="search-results"
      :height="searchResultsHeight"
    >
      <tbody v-if="isAccountSearch">
        <AccountSearchResults
          v-for="(item, i) in items"
          :key="item.id"
          :input="input"
          :item="item"
          :index="i"
          :highlightedRow="highlightedRow"
          ref="accountSearch"
          @accountClick="openAccountItem"
        />
      </tbody>
      <tbody v-else-if="isTargetSearch">
        <TargetSearchResults
          v-for="(item, i) in items"
          :key="item.id"
          :input="input"
          :item="item"
          :index="i"
          :highlightedRow="highlightedRow"
          ref="targetSearch"
          @targetClick="openTargetItem"
        />
      </tbody>
      <tbody v-else-if="isOperationSearch">
        <OperationSearchResults
          v-for="(item, i) in items"
          :key="item.id"
          :input="input"
          :item="item"
          :index="i"
          :highlightedRow="highlightedRow"
          ref="operationSearch"
          @operationClick="openOperationItem"
        />
      </tbody>
      <tbody v-else-if="isContactSearch">
        <ContactSearchResults
          v-for="(item, i) in items"
          :key="item.id"
          :input="input"
          :item="item"
          :index="i"
          :highlightedRow="highlightedRow"
          ref="contactSearch"
          @contactClick="openContact"
        />
      </tbody>
      <tbody v-else>
        <LegacySearchResults
          v-for="(item, i) in items"
          :key="item.id"
          :input="input"
          :item="item"
          :index="i"
          :highlightedRow="highlightedRow"
          ref="legacySearch"
          @legacyClick="openSearchItem"
        />
      </tbody>
    </v-simple-table>
  </v-menu>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import functions from '@/mixins/functions';
import AccountSearchResults from '@/views/employee/search/AccountSearchResults';
import LegacySearchResults from '@/views/employee/search/LegacySearchResults';
import TargetSearchResults from '@/views/employee/search/TargetSearchResults';
import OperationSearchResults from '@/views/employee/search/OperationSearchResults';
import ContactSearchResults from '@/views/employee/search/ContactSearchResults';

export default {
  name: 'HeaderSearch',

  components: {
    AccountSearchResults,
    LegacySearchResults,
    TargetSearchResults,
    OperationSearchResults,
    ContactSearchResults,
  },

  mixins: [functions],

  data: () => ({
    input: ``,
    items: [],
    request: null,
    value: null,
    highlightedRow: 0,
    show: false,
    isSearching: false,
  }),

  computed: {
    ...mapGetters('auth', ['canEditRecord']),

    isLoading(vm) {
      return vm.request !== null;
    },
    isNoResults(vm) {
      return vm.input && !vm.isLoading && vm.items.length === 0;
    },
    isLegacySearchResults(vm) {
      return (
        !vm.isAccountSearch &&
        !vm.isTargetSearch &&
        !vm.isOperationSearch &&
        !vm.isContactSearch
      );
    },
    isAccountSearch(vm) {
      return vm.searchRecord === 'accounts';
    },
    isTargetSearch(vm) {
      return vm.searchRecord === 'targets';
    },
    isOperationSearch(vm) {
      return vm.searchRecord === 'operations';
    },
    isContactSearch(vm) {
      return vm.searchRecord === 'contacts';
    },
    searchRecord: ({ $route }) => $route.path.split(`/`)[1],
    searchRecordTable: ({ searchRecord, $route }) =>
      $route.meta.searchRecord || searchRecord,
    searchResultsHeight: (vm) => {
      const rowHeight = 48;
      const maxRows = 5;
      if (vm.request || !vm.items.length) return rowHeight;
      return Math.min(vm.items.length, maxRows) * rowHeight;
    },
  },

  watch: {
    input(value) {
      this.searchFor(value);
    },

    $route() {
      this.input = ``;
    },
  },

  methods: {
    openAccountItem(item) {
      this.show = false;
      this.$router.push(`/accounts/${item.contactId}`);
    },
    openTargetItem(item) {
      this.show = false;
      this.$router.push(`/targets/${item.id}/info`);
    },
    openOperationItem(item) {
      this.show = false;
      this.$router.push(`/operations/${item.operationId}/info`);
    },
    openContact(item) {
      this.show = false;
      this.$router.push(`/contacts/${item.contactId}/info`);
    },
    openSearchItem(item) {
      if (!this.canEditRecord(item, true)) return;
      this.show = false;
      if (this.$route.meta.noL2) this.$refs.routerView.openRecord(item);
      else this.$router.push(`/${this.searchRecord}/${item.id}`);
    },
    openSearchResult() {
      const item = this.items[this.highlightedRow];
      if (this.isLegacySearchResults) {
        this.openSearchItem(item);
      }

      if (this.isAccountSearch) {
        this.openAccountItem(item);
      }

      if (this.isTargetSearch) {
        this.openTargetItem(item);
      }

      if (this.isOperationSearch) {
        this.openOperationItem(item);
      }
    },
    legacySearch(value) {
      const filters = Object.entries(this.$route.meta.searchFields)
        .filter(([, { searchable }]) => searchable)
        .map(([col]) => this.makeFilter(col, value, `like`));

      return this.crmRequest(
        `crud/${this.searchRecordTable}-view`,
        { filters, or: true },
        'GET'
      );
    },
    newSearch(value) {
      return this.crmRequest(
        `${this.searchRecordTable}`,
        { searchTerm: value },
        'GET'
      );
    },
    searchFor: debounce(async function (value) {
      if (this.request) {
        this.request.abort();
      }

      if (value) {
        const activeSearch = this.isLegacySearchResults
          ? this.legacySearch(value)
          : this.newSearch(value);

        this.setResults(activeSearch);
      } else {
        this.setResults(Promise.resolve([]));
      }
    }, 500),
    async setResults(activeSearch) {
      this.request = activeSearch;

      this.items = await activeSearch.then(
        (response) => response.value || response
      );
      this.highlightedRow = 0;
      this.request = null;
    },
    searchClickOutside() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field::v-deep {
  &,
  & * {
    height: 100%;
    max-height: unset !important;
  }
  .v-input__slot {
    padding-left: 12px;
  }
  .v-input__prepend-inner {
    margin: 0;
  }
}

.v-data-table::v-deep mark {
  background: rgba(98, 151, 73, 0.32);
  font-weight: bold;
}

.v-menu__content::v-deep {
  background-color: white !important;
}

.search-input::v-deep .v-input__slot:focus-within {
  background-color: var(--v-dark-lighten3) !important;
}

.search-input::v-deep .v-input__append-inner {
  margin-top: 0 !important;
  padding-right: 1rem !important;
}
</style>

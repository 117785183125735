import { saveDone } from '@/services/task-service';

const FARM_VISIT_ACTIVITY_TYPE_ID = 4;

export default {
  namespaced: true,

  state: {
    task: null,
    taskSaving: false,
    showModal: false,
    completedTaskId: -1,
  },

  actions: {
    markTaskComplete({ dispatch, commit }, task) {
      const showModal = shouldCollectCloseData(task);
      commit('setTask', [task, showModal]);

      if (!showModal) {
        dispatch('completeTask', [[], []]);
      }
    },

    closeModal({ commit }) {
      commit('setCompleted');
    },

    async completeTask({ commit, state }, [checkedTools, checkedCoSellers]) {
      const { task } = state;

      commit('setSaving', true);

      await saveDone(checkedTools, checkedCoSellers, task);

      commit('setCompleted', task.id);
    },
  },

  mutations: {
    setSaving(state, status) {
      state.taskSaving = status;
    },
    setTask(state, [task, showModal]) {
      state.task = task;
      state.showModal = showModal;
    },
    setCompleted(state, taskId) {
      state.task = null;
      state.taskSaving = false;
      state.showModal = false;
      state.completedTaskId = taskId;
    },
  },

  getters: {
    isCompleting: (state) => (t) => state.task === t,
    shouldCollectCloseData: () => (task) => shouldCollectCloseData(task),
  },
};

function shouldCollectCloseData(task) {
  return task.activity_type_id === FARM_VISIT_ACTIVITY_TYPE_ID;
}

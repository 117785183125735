import { getFeatureFlags } from '@/services/terraframing-service';

export default {
  namespaced: true,

  state: () => [],

  actions: {
    async fetch({ commit }) {
      const flags = await getFeatureFlags().catch(() => []);
      commit('set', flags);
    },
  },

  mutations: {
    set(state, enabledFlags) {
      state.length = 0;
      state.push(...enabledFlags);
    },
  },

  getters: {
    isOn: (enabledFlags) => (name) => {
      return enabledFlags.includes(name);
    },
  },
};

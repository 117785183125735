export function assignFieldsToBillingGroup({ state, commit }, group) {
  const billingGroups = state.billingGroups.map((billingGroup) => {
    if (billingGroup === group) {
      const fields = [...state.unassignedFields, ...state.assignableFields];

      return {
        ...group,
        fields: [...fields, ...group.fields],
        soilFields: [...fields, ...group.soilFields],
      };
    }

    return billingGroup;
  });

  commit('updateBillingGroups', {
    billingGroups,
    unassignedFields: [],
    assignableFields: [],
  });
}

import qs from 'qs';
// const tables = require('@/assets/tables.json');
import Auth from '@aws-amplify/auth';
import { v4 } from 'uuid';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import Papa from 'papaparse';

import getBaseUrl from '../services/base-farmgate-url';
import { getHost as getBaseTfsUrl } from '../services/terraframing-service';
import { calculateInvoiceTotals } from '@/services/invoice-total-service';

dayjs.extend(CustomParseFormat);
dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(require(`dayjs/plugin/advancedFormat`));

let user, session;

const env = process.env,
  { VUE_APP_STAGE, VUE_APP_S3_BUCKET_POSTFIX } = env,
  API_URL = `/api/`,
  // From https://cdn.jsdelivr.net/npm/moment-timezone/builds/moment-timezone-with-data-2012-2022.js
  TIME_ZONE_ALIASES = Object.fromEntries(
    [
      `America/Chicago|America/Indiana/Knox`,
      `America/Chicago|America/Indiana/Tell_City`,
      `America/Chicago|America/Knox_IN`,
      `America/Chicago|America/Matamoros`,
      `America/Chicago|America/Menominee`,
      `America/Chicago|America/North_Dakota/Beulah`,
      `America/Chicago|America/North_Dakota/Center`,
      `America/Chicago|America/North_Dakota/New_Salem`,
      `America/Chicago|America/Rainy_River`,
      `America/Chicago|America/Rankin_Inlet`,
      `America/Chicago|America/Resolute`,
      `America/Chicago|America/Winnipeg`,
      `America/Chicago|CST6CDT`,
      `America/Chicago|Canada/Central`,
      `America/Chicago|US/Central`,
      `America/Chicago|US/Indiana-Starke`,
      `America/Denver|America/Boise`,
      `America/Denver|America/Cambridge_Bay`,
      `America/Denver|America/Edmonton`,
      `America/Denver|America/Inuvik`,
      `America/Denver|America/Ojinaga`,
      `America/Denver|America/Shiprock`,
      `America/Denver|America/Yellowknife`,
      `America/Denver|Canada/Mountain`,
      `America/Denver|MST7MDT`,
      `America/Denver|Navajo`,
      `America/Denver|US/Mountain`,
      `America/New_York|America/Detroit`,
      `America/New_York|America/Fort_Wayne`,
      `America/New_York|America/Indiana/Indianapolis`,
      `America/New_York|America/Indiana/Marengo`,
      `America/New_York|America/Indiana/Petersburg`,
      `America/New_York|America/Indiana/Vevay`,
      `America/New_York|America/Indiana/Vincennes`,
      `America/New_York|America/Indiana/Winamac`,
      `America/New_York|America/Indianapolis`,
      `America/New_York|America/Iqaluit`,
      `America/New_York|America/Kentucky/Louisville`,
      `America/New_York|America/Kentucky/Monticello`,
      `America/New_York|America/Louisville`,
      `America/New_York|America/Montreal`,
      `America/New_York|America/Nassau`,
      `America/New_York|America/Nipigon`,
      `America/New_York|America/Pangnirtung`,
      `America/New_York|America/Thunder_Bay`,
      `America/New_York|America/Toronto`,
      `America/New_York|Canada/Eastern`,
      `America/New_York|EST5EDT`,
      `America/New_York|US/East-Indiana`,
      `America/New_York|US/Eastern`,
      `America/New_York|US/Michigan`,
    ].map((line) => line.split(`|`).reverse())
  ),
  { timeZone } = Intl.DateTimeFormat().resolvedOptions(),
  resolvedTimezone = TIME_ZONE_ALIASES[timeZone] || timeZone,
  TIMEZONE = Object.values(TIME_ZONE_ALIASES).includes(resolvedTimezone)
    ? resolvedTimezone.replace(/^America\//, ``)
    : `New_York`,
  loadedCdnScripts = {},
  TERRAFRAMING_URL = getBaseTfsUrl(),
  formatUSD = (value) => {
    value = value || 0;
    return value.toLocaleString(`en-us`, {
      currency: `USD`,
      style: `currency`,
    });
  };

export default {
  data: () => ({
    CUSTOMER_PORTAL_PATH_PREFIX: env.BASE_URL == `/` ? `/customer` : ``,
    API_URL,
    IS_CUSTOMER_PORTAL: location.hostname.indexOf('portal') !== -1,
    SUPPORT_PHONE_NUMBER: `(260) 557-1319`,
    TIMEZONE,
  }),

  computed: {
    canEditFilter() {
      const { getters } = this.$store;
      return getters[`auth/canGloballyEdit`]
        ? []
        : [
            this.makeFilter(
              getters[`auth/permissionCol`],
              getters[`auth/me`].id
            ),
          ];
    },
  },

  methods: {
    uuid: v4,
    makeName: (record) =>
      record && record.id
        ? [`first`, `last`]
            .map((name) => record[`${name}_name`])
            .filter((name) => name)
            .join(` `)
        : ``,
    makeFilter: (column, value, op = `=`) => ({ column, value, op }),
    toastMessage(content, color = `info`, icon) {
      const emit = (obj) => this.$store.commit(`app/snackbar`, obj);
      emit({ show: true, content, color, icon });
      setTimeout(() => emit({ show: false, content: `` }), 4000);
    },
    closeToast() {
      this.$store.commit(`app/snackbar`, { show: false, content: `` });
    },
    async aaRequest(path, data, method) {
      const isFormData = data instanceof FormData;
      const response = await fetch(`${TERRAFRAMING_URL}/api/${path}`, {
        method,
        headers: {
          ...(await this.getIdHeader()),
          ...(!isFormData && { 'Content-Type': `application/json` }),
        },
        ...(method == `POST` && {
          body: isFormData ? data : JSON.stringify(data),
        }),
      });
      let out = {};
      try {
        out = await response.json();
      } catch (e) {
        console.log('ERROR:', e);
      }
      return out;
    },
    formatDateTime(format, dateTimeStr, timeless) {
      if (typeof dateTimeStr == `boolean`) {
        timeless = dateTimeStr;
        dateTimeStr = undefined;
      }
      return (timeless ? dayjs.utc : dayjs)(dateTimeStr).format(format);
    },
    formatUSD,
    formatCustomerBalance: (customer) =>
      customer.locked_from_payment
        ? `Pending`
        : formatUSD(+customer.open_balance),

    formatWholeNumber: (value) =>
      value.toLocaleString(`en-us`, { maximumFractionDigits: 0 }),

    async getAuthHeader(tokenType) {
      try {
        if (!user || !session || !session.isValid()) {
          [user, session] = await Promise.all(
            [`AuthenticatedUser`, `Session`].map((method) =>
              Auth[`current${method}`]()
            )
          );
          user.setSignInUserSession(session);
        }
        return {
          Authorization: `${tokenType !== `Access` ? `Bearer ` : ``}${
            session[`get${tokenType}Token`]().jwtToken
          }`,
        };
      } catch (e) {
        /* anonymous request - no worries */
      }
    },
    async getIdHeader() {
      try {
        const session = await Auth.currentSession();
        return { Authorization: session.getIdToken().getJwtToken() };
      } catch (e) {
        return {}; // not logged in
      }
    },

    /**
     * submits requests to CRM db
     * @date 2021-09-02
     * @param {string} path
     * @param {Object} data
     * @param {string} method GET, POST, PUT, DELETE
     * @returns {any}
     */
    crmRequest(path, data, method) {
      // allows for cancelling requests, used in autocompletes
      let cancelled = false;
      const controller = new AbortController();
      return Object.assign(
        new Promise(async (resolve, reject) => {
          try {
            const impersonationId = this.$store?.getters?.[`auth/me`]?.id,
              isJSON = method.toLowerCase() != `get` || !data,
              fetchPath =
                getBaseUrl() + path + (isJSON ? `` : `?${qs.stringify(data)}`),
              requestHeaders = {
                ...(impersonationId && {
                  'X-Impersonation-id': impersonationId,
                }),
                ...(await this.getAuthHeader(`Id`)),
                ...(isJSON && { 'Content-Type': `application/json` }),
              };
            const val = await fetch(fetchPath, {
              signal: controller.signal,
              method,
              headers: requestHeaders,
              ...(isJSON && data && { body: JSON.stringify(data) }),
            });

            if (val.ok) resolve(cancelled ? null : val.json());
            else
              reject({
                response: cancelled ? null : await val.json(),
                status: val.status,
              });
          } catch (error) {
            if (error.name != `AbortError`) throw error;
          }
        }),
        {
          abort: () => {
            controller.abort();
            cancelled = true;
          },
        }
      );
    },
    toPascalCase: (str) =>
      str.slice(0, 1).toUpperCase() +
      str
        .slice(1)
        .replace(
          /[_-](\w)([a-zA-Z]+)/g,
          (_, $1, $2) => `${$1.toUpperCase()}${$2}`
        ),
    toCapitalCase: (str) =>
      str.slice(0, 1).toUpperCase() +
      str
        .slice(1)
        .replace(/(.)([A-Z])/, `$1 $2`)
        .replace(/[_-]/g, ` `),
    toTitleCase: (str) =>
      str.slice(0, 1).toUpperCase() +
      str
        .slice(1)
        .replace(
          /[_-](\w)([a-zA-Z]+)/g,
          (_, $1, $2) => ` ${$1.toUpperCase()}${$2}`
        ),
    toSlotName: (str) =>
      str.replace(/\s+/g, `-`).replace(/&/g, `and`).toLowerCase(),

    xs: (val) => (+val == 1 ? `` : `s`),
    xsLabel(val, label) {
      return `${val.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })} ${label}${this.xs(val)}`;
    },

    basePopup(options) {
      let resolve;
      const promise = new Promise((r) => (resolve = r));
      this.$store.commit(`app/popup`, { ...options, resolve });
      return promise;
    },

    errorAlert(message) {
      const opts = {
        message,
        alert: true,
        color: 'red',
        messageClass: 'alert-error',
        yes: 'OK',
      };
      return this.basePopup(opts);
    },

    alert(message) {
      return this.basePopup({ message, yes: `OK`, alert: true });
    },

    confirm(message, yes, title, styleOptions = {}) {
      return this.basePopup({
        message,
        no: styleOptions.noText
          ? styleOptions.noText
          : `No${yes.startsWith(`Cancel`) ? `` : `, Cancel`}`,
        yes: `Yes, ${yes}`,
        title: title && `${title}?`,
        ...styleOptions,
      });
    },

    prompt(label, yes, defaultText, maxLength) {
      return this.basePopup({
        prompt: label,
        no: `Close`,
        yes,
        input: defaultText,
        maxLength,
      });
    },

    /**
     * saves associated records
     * @param {Array<object>} recordsToSave - Array of record objects to save
     * @param {object} mixin - additional filters to apply {[`column`, value, operator]})
     * @param {string} endpoint - table to save to
     * @param {string} associativeCol - column to associate by
     * @param {object} [options={}]
     * @param {boolean} [options.uuid] - whether creating row should create and attach new uuid
     * @param {boolean} [options.softDelete] - whether row should be marked deleted or actually deleted
     * @returns {Promise}
     */
    async saveAssociations(
      recordsToSave,
      mixin,
      endpoint,
      associativeCol,
      { uuid, softDelete } = {}
    ) {
      const { crmRequest } = this,
        existingRecordsByAssociativeCol = Object.fromEntries(
          (
            await crmRequest(
              `crud/${endpoint}`,
              {
                amount: `all`,
                filters: Object.entries(mixin).map((entry) =>
                  this.makeFilter(...entry)
                ),
              },
              `GET`
            )
          ).value.map((record) => [record[associativeCol], record.id])
        );

      await Promise.all([
        ...recordsToSave.map(async (recordToSave) => {
          const associativeVal = recordToSave[associativeCol],
            id = existingRecordsByAssociativeCol[associativeVal];
          delete existingRecordsByAssociativeCol[associativeVal];
          const { value } = await crmRequest(
            `crud/${endpoint}${id ? `/${id}` : ``}`,
            { ...recordToSave, ...mixin, ...(uuid && { uuid: this.uuid() }) },
            id ? `PUT` : `POST`
          );
          if (!id) this.$set(recordToSave, `id`, value.id);
        }),

        ...Object.values(existingRecordsByAssociativeCol).map((id) =>
          crmRequest(
            `crud/${endpoint}/${id}`,
            ...(softDelete ? [{ deleted: 1 }, `PUT`] : [{}, `DELETE`])
          )
        ),
      ]);
    },

    async getContactToOperationRelationships(primaryType, { id }) {
      const isContactPrimary = primaryType == `contact`,
        relationshipType = isContactPrimary ? `operation` : `contact`,
        { value } = await this.crmRequest(
          `crud/contact-to-operation`,
          {
            filters: [this.makeFilter(`${primaryType}_id`, id)],
            amount: `all`,
            ...(isContactPrimary || {
              sortBy: [`sort_order`],
              sortDesc: [false],
            }),
          },
          `GET`
        ),
        recordsById = value.length
          ? Object.fromEntries(
              (
                await this.crmRequest(
                  `crud/${relationshipType}s-view`,
                  {
                    filters: [
                      this.makeFilter(
                        `id`,
                        value.map(
                          (relationship) =>
                            relationship[`${relationshipType}_id`]
                        ),
                        `in`
                      ),
                    ],
                    amount: `all`,
                  },
                  `GET`
                )
              ).value.map((record) => [record.id, record])
            )
          : {};
      return {
        contactToOperation: value.map((relationship) => ({
          ...relationship,
          [relationshipType]:
            recordsById[relationship[`${relationshipType}_id`]],
        })),
      };
    },

    async getOperationOrContactInfo(primaryType, record, addresses) {
      return Object.assign(
        {},
        ...(await Promise.all([
          // Get addresses
          ...Object.entries(addresses).map(async ([type, idCol]) => {
            const getRecord = async (typeAndId) =>
                (await this.crmRequest(`crud/${typeAndId}`, {}, `GET`))
                  .value[0],
              address = await getRecord(
                `address/${record[`${idCol ? `${idCol}_` : ``}address_id`]}`
              ),
              { county_id } = address,
              county = county_id
                ? await getRecord(`county/${county_id}`)
                : null;

            return {
              [`${type ? `${type}A` : `a`}ddress`]: {
                ...address,
                county,
                state: county
                  ? this.$store.getters[`crm/StateById`](
                      county.state_province_id
                    )
                  : null,
              },
            };
          }),

          // Get relationships
          this.getContactToOperationRelationships(primaryType, record),
        ]))
      );
    },

    objFromKeys: (...keys) =>
      Object.fromEntries(
        keys.map((key) => [key, key.endsWith(`_id`) ? 0 : ``])
      ),

    deepCopy: (object) => JSON.parse(JSON.stringify(object)),

    deepDiff(a, b) {
      const diffEntries = Object.entries(a).flatMap(([k, v]) => {
        if (typeof v != `object`) return v == b[k] ? [[k, v]] : [];
        const diff = this.deepDiff(v, b[k]);
        return diff ? [[k, diff]] : [];
      });
      return diffEntries.length && Object.fromEntries(diffEntries);
    },

    roundTo2: (n) => +n.toFixed(2),

    sumArray(array) {
      return this.roundTo2(array.reduce((a, b) => a + b, 0));
    },

    sumObjectValues(obj) {
      return this.roundTo2(
        Object.values(obj).reduce((a, b) => a + (b || 0), 0)
      );
    },

    truncateTo2: (n) => +/^-?\d+(\.\d{0,2})?/.exec(n)[0],

    createLoadOrDefault(condition) {
      return (type, data, defaultVal) =>
        condition
          ? this.crmRequest(`crud/${type}`, data, `GET`).then(
              ({ value }) => value
            )
          : Promise.resolve(defaultVal);
    },

    getFileExtension: ({ name }) => name.split(`.`).pop().toLowerCase(),

    getS3Bucket: (type) =>
      `adag-crm-${type}s-${
        VUE_APP_STAGE == `dev` ? `qa` : VUE_APP_STAGE
      }${VUE_APP_S3_BUCKET_POSTFIX}/`,

    getS3PictureUrl(url) {
      return `https://s3.amazonaws.com/${this.getS3Bucket(`picture`)}${url}`;
    },

    async filesRequest(path, data, method) {
      return (
        await this.crmRequest(
          `files/${this.getS3Bucket(`file`)}${path}`,
          data,
          method
        )
      ).value;
    },

    async uploadToS3(path, file) {
      const url = await this.filesRequest(path, {}, `POST`);
      return fetch(url, { body: file, method: 'PUT' });
    },

    getSignature() {
      return this.filesRequest(
        `signature/${this.$store.getters[`auth/me`].id}.png`,
        {},
        `GET`
      );
    },

    filterAndJoin: (separator, ...values) =>
      values.filter((v) => v).join(separator),

    formatAddress(address) {
      const { filterAndJoin } = this,
        addressFields = (...fields) => fields.map((field) => address[field]);
      return filterAndJoin(
        `, `,
        ...addressFields(`address`, `city`),
        filterAndJoin(` `, ...addressFields(`state_name`, `zip`))
      );
    },

    calculateInvoiceTotals(_, invoiceLineItems) {
      return calculateInvoiceTotals(invoiceLineItems);
    },

    getSendToHtml: (emails) =>
      `to ` +
      emails
        .map((email, i, { length }) => {
          let delim = ``;
          if (i + 1 < length)
            if (length == 2) delim = ` and `;
            else delim = `, ${i + 2 == length ? `and ` : ``}`;

          return `<b>${email}</b>${delim}`;
        })
        .join(``) +
      `?`,

    setInvoiceIsOverdue(invoice) {
      const overdue = !dayjs(invoice.due_date.slice(0, -1)).isAfter();
      return {
        ...invoice,
        overdue,
        status: overdue ? `Open (Overdue)` : invoice.status,
      };
    },

    getS3ObjectUrl: (downloadOrView, { id, uuid }, append = ``) =>
      `${API_URL}pdf/${downloadOrView}/${id}/${uuid}${append}`,

    getBoundaryManagementUrl({ aa_guid }) {
      return `${TERRAFRAMING_URL}/field-boundary-management/${aa_guid}`;
    },

    async quickRecord(type, existingRecord) {
      const { modals } = this.$root,
        newRecord = await new Promise((resolve) =>
          modals.push({ type, existingRecord, resolve })
        );
      modals.pop();
      return newRecord;
    },

    loadScript(src) {
      if (src.includes(`/cdn`)) {
        if (loadedCdnScripts[src]) return;
        loadedCdnScripts[src] = true;
      }
      return new Promise((onload) =>
        document.body.append(
          Object.assign(document.createElement(`script`), { onload, src })
        )
      );
    },

    publicImage(src) {
      return `${this.$router.options.base}img/${src}.png`;
    },

    downloadReport(data, reportName) {
      const dl = Object.assign(document.createElement(`a`), {
        href: `data:text/plain;charset=utf-8,${encodeURIComponent(
          Papa.unparse(data)
        )}`,
        download: `${reportName}.csv`,
      });
      document.body.appendChild(dl);
      dl.click();
      document.body.removeChild(dl);
    },

    show4In1(...invoices) {
      return (
        invoices.length &&
        invoices.every(
          (invoice) => invoice.four_payment_savings && !invoice.open_four_in_one
        )
      );
    },

    async giveAccessToOperationInTerraframing(aa_guid) {
      if (aa_guid && !this.contactSigners)
        for (const action of [`remove`, `add`]) {
          const args = {
            username: (await Auth.currentAuthenticatedUser()).username,
            growerId: aa_guid,
          };
          await this.aaRequest(`users/growers/${action}`, args, `POST`);
        }
    },
  },
};

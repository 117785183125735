import dayjs from 'dayjs';
import findKey from 'lodash/findKey';
import { BILLING_CYCLES } from '@/utils/constants';
import { parsePhoneNumber } from 'awesome-phonenumber';

export const makeFilter = (column, value, op = '=') => ({ column, value, op });

export const spacerButton = () => ({
  ' ': null,
});

export const makeButton = (label, click, color, icon, disabled) => ({
  [label]: { icon, click, color, disabled },
});

export function isValidPhoneNumber(phone) {
  return parsePhoneNumber(phone, { regionCode: 'US' }).valid;
}

export function getBillingCycleFromBillDate(billingDate) {
  const month = dayjs(billingDate).format('MMM');

  const cycle = findKey(BILLING_CYCLES, (months) => months.includes(month));

  return parseInt(cycle);
}

import { post } from '@/services/api-service';

export async function convertTarget(
  { commit },
  { contractId, sendEmail, targetId }
) {
  try {
    commit('updateBillingGroups', { saving: true });

    await post(`sow/${contractId}/convert`, { targetId, sendEmail });

    commit('updateBillingGroups', { saving: false, isComplete: true });
  } catch (e) {
    commit('updateBillingGroups', { saving: false, showError: true });
  }
}

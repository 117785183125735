import * as accountService from '../services/account-service';

export default {
  namespaced: true,

  state: {
    account: undefined,
    billingComments: undefined,
    loading: false,
    transactions: undefined,
  },

  getters: {
    account: ({ account }) => {
      return account;
    },
    billingComments: ({ billingComments }) => {
      return billingComments;
    },
    transactions: ({ transactions }) => {
      return transactions;
    },
  },

  actions: {
    async loadAccount({ commit, dispatch }, contactId) {
      commit('updateAccount', {
        loading: true,
      });

      try {
        const account = await accountService.getAccount(contactId);

        const billingComments = await accountService.getBillingRecordComments(
          contactId
        );

        const transactions = await accountService.getTransactions(contactId);

        commit('updateAccount', {
          loading: false,
          account,
          billingComments,
          transactions,
        });
      } catch (e) {
        dispatch('app/showError', 'Error retrieving account', {
          root: true,
        });
        commit('updateAccount', { loading: false });
      }
    },
  },

  mutations: {
    updateAccount(state, payload) {
      Object.assign(state, payload);
    },
  },
};

<template>
  <v-chip
    :color="`${statusColor}_background`"
    :text-color="statusColor"
    v-bind="$attrs"
  >
    {{ getStatus(``) }}
  </v-chip>
</template>

<script>
import functions from '@/mixins/functions';

export default {
  name: `TransactionStatusChip`,
  mixins: [functions],
  props: { item: Object },
  computed: {
    statusColor: (vm) =>
      ({
        1: ``,
        2: `info`,
        3: `success`,
        4: `error`,
        5: ``,
        6: `info`,
        7: `warning`,
        8: `success`,
        9: `info`,
        10: `info`,
        11: `error`,
      }[vm.getStatus(`_id`)]),
  },
  methods: {
    getStatus(postfix) {
      return this.item[
        `status${postfix}${this.IS_CUSTOMER_PORTAL ? `_customer` : ``}`
      ];
    },
  },
};
</script>

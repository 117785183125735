import { get, put } from './api-service';
import { makeFilter } from '@/utils/util';

export async function getAccount(contactId) {
  return await get(`accounts/${contactId}`);
}

export async function getBillingRecordComments(contactId) {
  return await get(`account/${contactId}/billing-comments`);
}

export async function getTransactions(contactId) {
  const transactions = (
    await get('crud/transactions-view', {
      filters: [makeFilter('contact_id', contactId)],
      amount: 'all',
      sortBy: ['date'],
      sortDesc: [true],
    })
  ).value;

  return transactions.map((transaction) => {
    let out = {};
    const { category } = transaction;
    const url = makeUrl(category, contactId, transaction);

    out = Object.assign({}, transaction, { url });
    return out;
  });
}

export async function updateAccount(contactId, updateFields) {
  return await put(`accounts/${contactId}`, updateFields);
}

export async function archiveCredit(transactionId) {
  return await put(`credits/${transactionId}`);
}

function makeUrl(category, contactId, transaction) {
  const normalizedCategory = category.toLowerCase().replace(` `, `-`);
  const transactionPath = `${normalizedCategory}s/${transaction.id}`;

  if ([`Invoice`, `Payment`].includes(category)) {
    return `/${transactionPath}`;
  } else {
    return `/accounts/${contactId}/${transactionPath}`;
  }
}

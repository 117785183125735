<template>
  <tr
    :class="{ gray: highlightedRow == index }"
    style="cursor: pointer"
    @click="$emit('targetClick', item)"
  >
    <td><Highlighter :value="item.contactName" :highlight="input" /></td>
    <td><Highlighter :value="item.operationName" :highlight="input" /></td>
    <td><Highlighter :value="item.agronomistName" :highlight="input" /></td>
    <td><Highlighter :value="item.targetStatusName" :highlight="input" /></td>
  </tr>
</template>

<script>
import Highlighter from '@/components/Highlighter';

export default {
  name: 'TargetSearchResults',

  components: {
    Highlighter,
  },

  props: { input: String, item: Object, index: Number, highlightedRow: Number },
};
</script>

<template>
  <tr
    :class="{ gray: highlightedRow == index }"
    style="cursor: pointer"
    @click="$emit('contactClick', item)"
  >
    <td><Highlighter :value="item.contactName" :highlight="input" /></td>
    <td><Highlighter :value="cityState" :highlight="input" /></td>
    <td><Highlighter :value="item.county" :highlight="input" /></td>
    <td><Highlighter :value="item.agronomistName" :highlight="input" /></td>
  </tr>
</template>

<script>
import Highlighter from '@/components/Highlighter';

export default {
  name: 'ContactSearchResults',

  components: {
    Highlighter,
  },

  props: {
    input: String,
    item: Object,
    index: Number,
    highlightedRow: Number,
  },

  computed: {
    cityState: (vm) => {
      return `${vm.item.city}, ${vm.item.stateName}`;
    },
  },
};
</script>

<template>
  <v-navigation-drawer
    dark
    color="dark"
    width="208px"
    :app="true"
    :value="showNavDrawer"
  >
    <PublicImage src="Dark Horizontal" style="max-height: 56px" />
    <v-list shaped dense class="pt-0">
      <template v-for="(item, i) in navigationItems">
        <v-list-group
          v-if="item.children.length > 0"
          :key="i"
          value="true"
          no-action
          :append-icon="``"
          color="white"
        >
          <template #activator>
            <v-list-item-avatar size="small" rounded="0">
              <v-icon>mdi-{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-title data-testid="nav-items"
              >{{ item.name }}
            </v-list-item-title>
          </template>
          <NavItem
            data-testid="nav-items"
            v-for="child in item.children"
            :key="child.name"
            :item="child"
            class="pl-9"
          />
        </v-list-group>
        <NavItem data-testid="nav-items" v-else :key="i" :item="item" exact />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import PublicImage from '@/components/misc/PublicImage';
import NavItem from '@/components/misc/NavItem';
import { mapGetters } from 'vuex';
import navItems from './navigation';
import filter from 'lodash/filter';
import toPairs from 'lodash/toPairs';

export default {
  name: `EmployeeLeftNavigation`,

  components: {
    PublicImage,
    NavItem,
  },
  props: {
    showNavDrawer: Boolean,
  },

  computed: {
    ...mapGetters(`crm`, [`USER_GROUP`]),
    ...mapGetters(`auth`, [`me`]),

    userRole: (vm) => {
      return toPairs(vm.USER_GROUP).find(
        ([_, id]) => id === vm.me.user_group_id
      )[0];
    },

    navigationItems() {
      return navItems.filter(this.isAuthorized).map((parent) => ({
        ...parent,
        children: filter(parent.children, this.isAuthorized),
      }));
    },
  },

  methods: {
    isAuthorized(item) {
      return item.roles.includes(this.userRole);
    },
  },
};
</script>

<template>
  <v-chip :color="`${statusColor}_background`" :text-color="statusColor">{{
    status
  }}</v-chip>
</template>

<script>
import functions from '@/mixins/functions';

export default {
  name: `BillingRecordStatusChip`,

  mixins: [functions],

  props: {
    status: String,
    statusId: Number,
  },

  computed: {
    statusColor: ({ statusId }) =>
      ({
        1: ``,
        2: `success`,
        3: `error`,
      }[statusId]),
  },
};
</script>

<template>
  <v-app style="background: #eaeaea">
    <template v-if="IS_CUSTOMER_PORTAL > -1">
      <div class="d-flex flex-column" style="min-height: 100vh">
        <template v-if="IS_CUSTOMER_PORTAL">
          <div>
            <!--<link rel='stylesheet' href='https://advancedagrilytics.com/css/sidebar-nav.css'>-->
            <header id="page-header">
              <div class="header-top" style="background: '#33691E'" />
              <div class="header-bottom">
                <PortalUserMenu />
              </div>
            </header>
            <!-- /#page-header -->
          </div>
        </template>

        <div v-if="loggedIn" class="d-flex" style="height: 100%">
          <Impersonator
            v-if="shouldShowCustomerPortal"
            style="
              position: absolute;
              top: 0;
              right: 0;
              height: auto;
              z-index: 1000;
            "
          />
          <EmployeeLeftNav
            v-if="me && !shouldShowCustomerPortal"
            :showNavDrawer="showNavDrawer"
          />
          <v-app-bar
            v-if="showNavigation && !IS_CUSTOMER_PORTAL"
            flat
            short
            :app="!IS_CUSTOMER_PORTAL"
            color="dark"
          >
            <v-app-bar-nav-icon
              v-if="$vuetify.breakpoint.mdAndDown"
              style="margin-left: 1px"
              class="white--text"
              @click.stop="showNavDrawer = !showNavDrawer"
              data-testid="navDrawerMenu"
            />

            <HeaderSearch />
            <v-menu
              tile
              offset-y
              :max-width="$vuetify.breakpoint.mobile ? `100%` : 360"
              :max-height="$vuetify.breakpoint.mobile ? `92vh` : `50vh`"
              min-width="360"
              :nudge-left="$vuetify.breakpoint.mobile ? 0 : ``"
              @input="onMenuInput"
            >
              <template #activator="{ on }">
                <v-btn icon dark class="toolbar-button h-100" tile v-on="on">
                  <v-badge
                    v-if="notificationsBadgeCount"
                    :content="notificationsBadgeCount"
                    color="primary"
                    overlap
                  >
                    <v-icon style="color: white !important"
                      >mdi-bell-outline</v-icon
                    >
                  </v-badge>
                  <v-icon v-else>mdi-bell-outline</v-icon>
                </v-btn>
              </template>
              <v-toolbar
                color="secondary_medium"
                class="pr-0"
                dark
                flat
                height="36"
              >
                <!-- Phase 2 -->
                <!--v-btn icon>
												<v-icon>mdi-cog</v-icon>
											</v-btn-->
                <v-toolbar-title class="subtitle-1 font-weight-medium"
                  >Notifications</v-toolbar-title
                >
                <v-spacer />
                <v-btn
                  v-if="notifications.length"
                  text
                  @click="clearAllNotifications"
                  >Clear All</v-btn
                >
              </v-toolbar>
              <v-list
                v-if="notifications.length"
                three-line
                subheader
                class="pb-0 white"
                style="overflowy: scroll"
              >
                <v-expand-transition
                  v-for="(notification, i) in notifications"
                  :key="notification.id"
                >
                  <v-hover v-slot="{ hover }">
                    <v-list-item
                      style="min-height: 72px"
                      :style="{
                        background: notification.status
                          ? `primary_background`
                          : notification.icon == `task`
                          ? `warning_background`
                          : `white`,
                      }"
                      :to="notification.permalink"
                    >
                      <RecordIcon
                        :record="notification.icon"
                        :avatar-props="{ size: 40 }"
                        class="mr-2"
                      />
                      <v-list-item-content
                        :class="{
                          'font-weight-medium': notification.status == 1,
                        }"
                      >
                        <v-list-item-title
                          style="whitespace: unset !important"
                          v-html="notification.title"
                        />
                        <v-list-item-subtitle
                          class="task-description"
                          v-html="notification.description"
                        />
                        <div
                          class="body-2 primary--text"
                          :class="{
                            'font-weight-medium': notification.status == 1,
                          }"
                        >
                          {{
                            [`subtitle_record_type`, `record_name`]
                              .map((field) => notification[field])
                              .filter((val) => val)
                              .join(` • `)
                          }}
                        </div>
                      </v-list-item-content>
                      <v-list-item-action
                        class="text-right caption align-self-start"
                        :class="
                          !notification.status && notification.icon == `task`
                            ? `warning--text`
                            : `text--secondary`
                        "
                      >
                        <v-btn
                          icon
                          :style="hover || { opacity: 0 }"
                          style="transition: opacity 0.3s; position: absolute"
                          @click.prevent="clearNotification(i)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <div
                          style="transition: opacity 0.3s"
                          :style="
                            hover && { opacity: 0, pointerEvents: `none` }
                          "
                        >
                          <span class="text-no-wrap">
                            <span style="vertical-align: middle">
                              {{
                                dayjs().diff(notification.date_created, `m`)
                                  ? [`Today`, `Yesterday`].find(
                                      (label, difference) =>
                                        [
                                          dayjs(notification.date_created),
                                          dayjs().subtract(difference, `d`),
                                        ]
                                          .map((date) =>
                                            date.format(`YYYYMMDD`)
                                          )
                                          .reduce((a, b) => a == b)
                                    ) ||
                                    formatDateTime(
                                      `MMMM D`,
                                      notification.date_created
                                    )
                                  : `Now`
                              }}
                            </span>
                            <v-icon
                              v-if="notification.status"
                              color="primary"
                              style="width: 12px"
                              >mdi-circle-medium</v-icon
                            >
                          </span>
                          <template v-if="notification.icon == `task`">
                            <br />{{
                              formatDateTime(
                                `h:mm A`,
                                notification.date_created
                              )
                            }}
                          </template>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-hover>
                </v-expand-transition>
              </v-list>
              <div
                v-else
                class="py-8 text-center subtitle-1 text--disabled white"
              >
                No notifications
              </div>
            </v-menu>
            <v-menu tile offset-y left bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  dark
                  class="toolbar-button h-100 d-none d-sm-flex"
                  tile
                  v-on="on"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>
              <v-list width="250" class="py-0">
                <v-list-item
                  v-for="{ pascalType, type } in newRecordTypeOptions.filter(
                    ({ type, showInMenu }) =>
                      !type.startsWith(`quick`) && showInMenu
                  )"
                  :key="type"
                  @click="setNewRecordOpen(pascalType, true)"
                >
                  <RecordIcon
                    :avatar-props="{ size: 32 }"
                    :icon-props="{ size: `medium` }"
                    :record="type"
                    class="mr-1"
                  />
                  <v-list-item-title>New {{ pascalType }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu tile offset-y left bottom>
              <template #activator="{ on }">
                <v-btn
                  dark
                  class="toolbar-button h-100"
                  large
                  color="transparent"
                  elevation="0"
                  tile
                  v-on="on"
                >
                  <Avatar :name="name" :img-src="me.profile_picture_key" />
                  <v-icon dark>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list width="250" class="py-0">
                <v-list-item two-line>
                  <v-list-item-avatar :size="32">
                    <Avatar
                      :name="name"
                      :img-src="me.profile_picture_key"
                      :size="32"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-weight: 500; line-height: 20px"
                      >{{ name }}</v-list-item-title
                    >
                    <v-list-item-subtitle style="letter-spacing: 0.4px">
                      {{ UserGroupById(me.user_group_id).name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item
                  v-for="(item, title) in topRightMenu"
                  :key="title"
                  @click="item.click"
                >
                  <v-list-item-avatar
                    ><v-icon>mdi-{{ item.icon }}</v-icon></v-list-item-avatar
                  >
                  <v-list-item-title>{{ title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <Impersonator
              class="flex-grow-0 impersonator-input d-none d-sm-flex"
            />
          </v-app-bar>

          <v-main>
            <HeaderAlert v-if="!IS_CUSTOMER_PORTAL && showNavigation" />
            <transition name="fade">
              <!--
								Abraham, 5/7/21: This was causing double scrollbars on SFA PDFs. This likely needs to be put back, but it
								needs to be investigated further before it is put back.

								style='overflow: auto;'
							-->
              <router-view v-if="hasConfigBeenFetched" ref="routerView" />
              <v-container v-else>
                <v-skeleton-loader
                  data-testid="appLoading"
                  loading
                  type="table"
                />
              </v-container>
            </transition>
          </v-main>

          <template v-if="showNavigation">
            <component
              :is="type.component"
              v-for="type in newRecordTypeOptions.filter(
                ({ type }) => newRecordTypes[type].open
              )"
              :key="type.type"
              v-bind="newRecordTypes[type.type].props"
              @hidden="setNewRecordOpen(type.pascalType, false)"
            />
            <component
              :is="modal.component"
              v-for="(modal, i) in modals"
              :key="i"
            />
            <v-snackbar v-model="showNewVersionSnackbar" light timeout="-1">
              Looks like a new version of Farmgate just got released! Refresh
              your page to get all the latest updates.
              <template #action="{ attrs }">
                <v-btn :href="$route.path" icon v-bind="attrs"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
                <v-btn
                  icon
                  v-bind="attrs"
                  @click="showNewVersionSnackbar = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template>
            </v-snackbar>
          </template>

          <v-dialog
            :value="!!popup"
            :width="
              popup && popup.customWidth
                ? popup.customWidth
                : popup && popup.prompt
                ? 566
                : 280
            "
            @input="hidePopup"
          >
            <v-card v-if="popup">
              <v-card-title
                v-if="popup.title"
                :class="popup.titleClass"
                v-html="popup.title"
              />
              <v-card-text data-testid="popupText">
                <div
                  :class="[popup.messageClass, popup.alert && `pt-6`]"
                  v-html="popup.message"
                />
                <FormInput
                  v-if="popup.prompt"
                  v-model="popupInput"
                  class="pt-6 pa-0"
                  :label="popup.prompt"
                  :maxlength="popup.maxLength"
                />
              </v-card-text>
              <v-card-actions
                :class="{
                  'justify-end': popup.prompt,
                  'd-flex justify-end': popup.btnAlignRight,
                }"
              >
                <v-btn
                  v-for="({ btn, color, text }, yes) in Object.entries({
                    no: `dark`,
                    yes: popup.yesBtnColor,
                  })
                    .map(([btn, color]) => ({ btn, color, text: popup[btn] }))
                    .filter(({ text }) => text)"
                  :key="btn"
                  :style="popup.btnHeight && { height: `${popup.btnHeight}px` }"
                  :color="
                    !!yes && popup.yesBtnColor ? popup.yesBtnColor : color
                  "
                  :disabled="!!yes && popup.prompt && !popupInput"
                  @click="
                    popup.resolve(popup.prompt && yes ? popupInput : !!yes);
                    hidePopup();
                  "
                >
                  {{ text }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <div
          v-else
          class="d-flex flex-column align-center"
          style="margin: 120px 0"
        >
          <PublicImage
            v-if="!IS_CUSTOMER_PORTAL"
            src="FG Light Horizontal"
            class="mx-auto mt-auto"
          />

          <v-alert v-if="showCustomerPortalUserError" color="error" text>
            It looks like you are attempting to log in with a user that does not
            have access to Customer Portal. Please create a new Customer Portal
            user by clicking the link below.
          </v-alert>

          <amplify-authenticator
            ref="authenticator"
            class="mx-4 rounded"
            style="
              border: 1px solid rgba(0, 0, 0, 0.24);
              --amplify-background-color: var(--v-light-base);
              --amplify-font-family: Roboto, sans-serif;
              --amplify-primary-color: var(--v-primary-base);
              --amplify-text-md-sub: 24px;
              --box-shadow: none;
              --container-height: 0;
              --header-size: 40px;
              --margin-bottom: 0;
              --padding: 24px 24px 30px;
              --width: 330px;
            "
          >
            <amplify-sign-in
              slot="sign-in"
              :header-text="
                IS_CUSTOMER_PORTAL
                  ? `Customer Portal Login`
                  : `Sign in to Farmgate`
              "
              hide-sign-up
              submit-button-text="Login"
            />
          </amplify-authenticator>
          <template v-if="IS_CUSTOMER_PORTAL">
            <p class="create-account">
              Don't have an account yet?
              <a @click.stop="showCreateAccount = true"
                >Click here to create your account.</a
              >
            </p>
            <CreateAccountInitModal :is-active="showCreateAccount" />
          </template>
        </div>

        <v-alert
          v-if="IS_CUSTOMER_PORTAL"
          info="primary"
          text
          class="mt-auto mb-0"
          rounded="0"
        >
          For any questions about your account or to get help with technical
          issues, email
          <Mailto email="help@advancedagrilytics.com" />.
        </v-alert>
      </div>

      <TaskCloseConfirmationModal />
    </template>

    <v-alert
      v-else
      color="error"
      text
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      This is not a supported domain for accessing Farmgate.
    </v-alert>

    <div
      v-if="IS_QA"
      class="d-flex justify-space-between"
      style="
        position: fixed;
        z-index: 10;
        opacity: 0.5;
        pointer-events: none;
        bottom: 0;
        width: 100vw;
      "
    >
      <div
        v-for="i in 4"
        :key="i"
        class="text-center"
        style="background: red; color: white"
      >
        THIS IS THE QA ENVIRONMENT.<br />THIS SITE IS FOR TESTING PURPOSES ONLY.
      </div>
    </div>
  </v-app>
</template>

<script>
import Avatar from '@/components/misc/Avatar';
import PortalUserMenu from '@/components/customer-portal/user/PortalUserMenu';
import NavItem from '@/components/misc/NavItem';
import functions from '@/mixins/functions';
import { mapGetters, mapState } from 'vuex';
import FormInput from '@/components/form-inputs/FormInput';
import Auth from '@aws-amplify/auth';
import RecordIcon from '@/components/misc/RecordIcon';
import dayjs from 'dayjs';
import { onAuthUIStateChange, Translations } from '@aws-amplify/ui-components';
import Impersonator from '@/components/form-inputs/Impersonator';
import { I18n } from 'aws-amplify';
import PublicImage from '@/components/misc/PublicImage';
import HeaderAlert from '@/components/misc/HeaderAlert';
import HeaderSearch from '@/components/misc/HeaderSearch';
import BillingRecordStatusChip from '@/components/accounting/BillingRecordStatusChip';
import TransactionStatusChip from '@/components/accounting/TransactionStatusChip.vue';
import Mailto from '@/components/misc/Mailto';
import CreateAccountInitModal from '@/components/customer-portal/CreateAccountInitModal.vue';
import TaskCloseConfirmationModal from '@/components/tasks/TaskCloseConfirmationModal';
import EmployeeLeftNav from '@/components/navigation/EmployeeLeftNav';

I18n.putVocabulariesForLanguage(
  `en-US`,
  Object.fromEntries(
    Object.values(Translations).map((val) => [
      val,
      val.replace(/^Enter your (.)/, (match, c) => c.toUpperCase()),
    ])
  )
);

const env = process.env,
  { VUE_APP_STAGE, VUE_APP_PROD } = env,
  notDev = VUE_APP_STAGE != `dev`;

export default {
  name: `App`,

  components: {
    Mailto,
    Impersonator,
    Avatar,
    FormInput,
    NavItem,
    RecordIcon,
    PublicImage,
    HeaderAlert,
    BillingRecordStatusChip,
    TransactionStatusChip,
    CreateAccountInitModal,
    PortalUserMenu,
    TaskCloseConfirmationModal,
    EmployeeLeftNav,
    HeaderSearch,
  },

  mixins: [functions],

  data: (vm) => ({
    IS_QA: VUE_APP_STAGE == `qa`,

    location,

    hasConfigBeenFetched: false,

    creatingNewRecord: false,

    notifications: [],

    notificationInterval: null,

    popupInput: ``,

    dayjs,

    topRightMenu: {
      //"Notification Settings": { click: () => {}, icon: `bell-ring-outline` },
      'Log out': {
        click: async () => {
          await Auth.signOut();
          vm.init();
        },
        icon: `door-open`,
      },
    },

    newRecordTypeOptions: null,

    showNewVersionSnackbar: false,

    showNavDrawer: null,

    showCreateAccount: false,

    showCustomerPortalUserError: false,
  }),

  computed: {
    ...mapGetters(`crm`, [`UserById`, `UserGroupById`, `USER_GROUP`]),
    ...mapGetters(`auth`, [`me`, `canEditRecord`, 'isValidCustomerPortalUser']),
    ...mapState(`app`, [`newRecordTypes`, `popup`, `breadcrumb`]),
    ...mapState(`crm`, [`page`]),
    modals: (vm) =>
      (vm.$root.modals || []).map((options) => ({
        ...options,
        component: vm.newRecordTypes.find(
          ({ type }) => type == `quick${vm.toPascalCase(options.type)}`
        ),
      })),
    name: (vm) => vm.makeName(vm.me || /* for Vue Devtools */ {}),
    shouldShowCustomerPortal() {
      return this.IS_CUSTOMER_PORTAL && this.isValidCustomerPortalUser;
    },
    loggedIn() {
      if (!this.IS_CUSTOMER_PORTAL && this.me) {
        return true;
      }
      if (this.me && this.shouldShowCustomerPortal) {
        return true;
      }
      return this.unprotected;
    },
    notificationsBadgeCount: (vm) =>
      vm.notifications.filter(
        ({ status, record_type }) => status || record_type == `task`
      ).length,
    showNavigation: (vm) => vm.me && !vm.$route.meta.fullscreen,
    unprotected: ({ $route }) => $route.meta.unprotected,
  },

  watch: {
    'popup.input'(input) {
      this.popupInput = input;
    },
  },

  async created() {
    const { IS_CUSTOMER_PORTAL, init } = this;

    if (this.IS_CUSTOMER_PORTAL) {
      if (!this.$store.state.auth.customer) {
        this.$router.push({
          path: `${this.CUSTOMER_PORTAL_PATH_PREFIX}/logout`,
        });
      }
    }

    if (notDev) {
      const loadPageHtml = async () => (await fetch(``)).text();
      loadPageHtml().then(async (origHtml) => {
        while (1) {
          await new Promise((resolve) => setInterval(resolve, 60000));
          const newPageHtml = await loadPageHtml();
          if (newPageHtml != origHtml) {
            origHtml = newPageHtml;
            this.showNewVersionSnackbar = true;
          }
        }
      });
    }

    this.newRecordTypeOptions = Object.entries(this.newRecordTypes).map(
      ([type, { newPrefix, showInMenu, accounting }]) => {
        const pascalType = this.toPascalCase(type);
        return {
          type,
          showInMenu,
          pascalType,
          component: () =>
            import(
              `@/components/forms/${
                newPrefix
                  ? `new-record/${type.startsWith(`quick`) ? `quick/` : ``}New`
                  : accounting
                  ? `accounting/`
                  : ``
              }${pascalType}Form`
            ),
        };
      }
    );

    let user;

    onAuthUIStateChange((nextAuthState, user) => {
      init(user);
    });

    this.$watch(
      () => {
        const pathParts = this.$route.path
            .split(`/`)
            .slice(1 + (IS_CUSTOMER_PORTAL && env.BASE_URL == `/`)),
          [recordType] = pathParts,
          { breadcrumb } = this;
        if (pathParts[pathParts.length - 1] == ``) pathParts.pop();
        return [
          ...(VUE_APP_PROD ? [] : [VUE_APP_STAGE.toUpperCase()]),
          ...(pathParts.length > 1 && breadcrumb ? [breadcrumb] : []),
          ...(recordType
            ? [
                recordType
                  .replace(/-/g, ` `)
                  .replace(/\b./g, (c) => c.toUpperCase()),
              ]
            : []),
          IS_CUSTOMER_PORTAL ? `Advanced Agrilytics` : `Farmgate`,
        ].join(` - `);
      },
      (title) => (document.title = title),
      { immediate: true }
    );
  },

  methods: {
    clearAllNotifications() {
      while (this.notifications.length) this.clearNotification(0);
    },
    clearNotification(i) {
      this.crmRequest(
        `crud/notification/${this.notifications.splice(i, 1)[0].id}`,
        { status: -1 },
        `PUT`
      );
    },
    hidePopup() {
      this.$store.commit(`app/popup`);
    },
    async init(user) {
      const { IS_CUSTOMER_PORTAL, $store } = this;

      clearInterval(this.notificationInterval);

      this.hasConfigBeenFetched = false;

      await Promise.all(
        [
          `crm/fetchConfig`,
          ...(user
            ? [`auth/getMe`, `auth/getCustomer`, `featureFlags/fetch`]
            : []),
        ].map((method) => $store.dispatch(method))
      );
      this.hasConfigBeenFetched = true;

      // If we are authenticated, `getMe` will handle setting the AWS uuid
      if (user) {
        const { USER_GROUP, me, crmRequest } = this;

        const user_group = Object.keys(USER_GROUP).find(
          (key) => USER_GROUP[key] === me.user_group_id
        );

        this.$gtm.trackEvent({
          event: 'set_user_type',
          category: 'User',
          label: 'User Type',
          action: 'login',
          value: user_group,
          noninteraction: true,
        });

        if (!this.isValidCustomerPortalUser && IS_CUSTOMER_PORTAL) {
          this.showCustomerPortalUserError = true;
          await Auth.signOut();
          return;
        }

        // Check for customer operations that have not been linked to terraframing
        if (IS_CUSTOMER_PORTAL) {
          crmRequest(
            `crud/customer-operations-view`,
            { filters: [this.makeFilter(`linked_to_aa`, 0)] },
            `GET`
          ).then(({ value }) =>
            value.forEach(async ({ aa_guid, id }) => {
              await this.giveAccessToOperationInTerraframing(aa_guid);
              crmRequest(
                `crud/customer-operation/${id}`,
                { linked_to_aa: 1 },
                `PUT`
              );
            })
          );

          crmRequest(`crud/contact`, {}, `GET`).then(({ value }) => {
            try {
              localStorage.accountNumber = value[0].account_number;
            } catch (e) {}
          });
        } else {
          const loadNotifications = async () => {
            this.notifications = (
              await crmRequest(
                `crud/notifications-view`,
                {
                  amount: `all`,
                  filters: [this.makeFilter(`user_id`, me.id)],
                  sortBy: [`date_created`],
                  sortDesc: [false],
                },
                `GET`
              )
            ).value;
          };
          // noinspection ES6MissingAwait
          if (notDev) {
            loadNotifications();
            this.notificationInterval = setInterval(loadNotifications, 15000);
          }
        }
      } else {
        $store.commit(`auth/setAwsUuid`);
        /* Abraham, 1/11/21: Do NOT attempt to access localStorage if we are inside a frame. If the browser is
						 blocking 3rd party cookies (i.e. like how Chrome does in incognito by default) AND we are inside an
						 iframe (i.e. the signing process), then this will throw an error. */
        try {
          if (!user && window == top) {
            for (const key of Object.keys(localStorage))
              if (key.endsWith(`Datatable`)) localStorage.removeItem(key);
          }
        } catch (e) {}
      }
    },
    onMenuInput(shown) {
      if (!shown)
        for (const notification of this.notifications)
          if (notification.status) {
            notification.status = 0;
            this.crmRequest(
              `crud/notification/${notification.id}`,
              { status: 0 },
              `PUT`
            );
          }
    },
    setNewRecordOpen(type, open) {
      this.$store.commit(`app/setNew${type}Open`, { open });
    },
  },
};
</script>

<style lang="scss">
.v-application--wrap {
  > .v-navigation-drawer,
  > .v-toolbar {
    .v-icon,
    input::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.wide-panel > .v-expansion-panel-content__wrap {
  padding: 0 0 16px;
}
.bottom-toolbar {
  display: flex;
  flex-direction: column-reverse;
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc;
  }
}
.newsletter-email {
  background-color: rgb(255, 255, 255);
}
.primary-nav:not(.dropdown-nav):not(.show-nav) {
  display: none;
}
</style>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css');
@import './assets/css/header-footer.css?v=1.0.6';
</style>

<style lang="scss" scoped>
@media screen {
  #content-column {
    height: 100%;
  }
}
.v-app-bar::v-deep > .v-toolbar__content {
  padding: 0;
}
.toolbar-button {
  width: 4rem !important;
  &:focus {
    background-color: var(--primary) !important;
  }
}
.theme--dark.v-list-item--active {
  &:hover::before,
  &::before {
    opacity: 0;
  }
}
.v-text-field::v-deep {
  &,
  & * {
    height: 100%;
    max-height: unset !important;
  }
  .v-input__slot {
    padding-left: 12px;
  }
  .v-input__prepend-inner {
    margin: 0;
  }
}
.v-list-item__title {
  font-size: 13px;
}
.v-navigation-drawer::v-deep .v-list-item__title {
  font-weight: inherit;
}
.v-data-table::v-deep mark {
  background: rgba(98, 151, 73, 0.32);
  font-weight: bold;
}
.task-description::v-deep p:last-child {
  margin-bottom: 0;
}
.impersonator-input::v-deep .v-input__slot {
  margin-bottom: 0 !important;
}
.alert-error {
  color: red;
}
.create-account {
  padding: 24px 0;
  width: 330px;
}
.header-top {
  background: #4f771b;
  height: 30px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .header-top {
    margin: 0;
  }
}
.header-bottom {
  padding: 0 1rem;
}
::v-deep .row-pointer tbody tr :hover {
  cursor: pointer;
}
</style>

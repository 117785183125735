export function unassignField({ state, commit }, field) {
  const billingGroups = state.billingGroups.map((billingGroup) => {
    const fields = removeField(billingGroup.fields, field);
    const soilFields = removeField(billingGroup.soilFields, field);

    return { ...billingGroup, fields, soilFields };
  });

  const assignableFields = [...state.assignableFields, field];

  commit('updateBillingGroups', { billingGroups, assignableFields });
}

function removeField(fields, field) {
  return fields.filter((assignedField) => assignedField.aaid !== field.aaid);
}

import { formatUSD } from '@/utils/formatters';
import { AGRONOMY_SERVICES_PRODUCT, FINANCE_CHARGES } from '@/utils/constants';
import { round } from 'lodash';

export function calculateInvoiceTotals(invoiceLineItems) {
  const totals = {
    total: 0,
    lineItems: {
      annual: [],
      quarterly: [],
    },
  };

  return invoiceLineItems
    .map(calculateAmounts)
    .reduce(aggregateLineItems, totals);
}

function calculateAmounts(line) {
  if (isAgronomyServices(line) && shouldShowAutoGeneratedAmount(line)) {
    return getAutoGeneratedAmount(line);
  }

  if (isAgronomyServices(line)) {
    return getAgServicesAmount(line);
  }

  if (isFinanceCharges(line)) {
    return [undefined, line.auto_generated_quarterly_amount];
  }

  return getOneTimePaymentAmount(line);
}

function isAgronomyServices(line) {
  return line.product_service_id === AGRONOMY_SERVICES_PRODUCT;
}

function isFinanceCharges(line) {
  return line.product_service_id === FINANCE_CHARGES;
}

function shouldShowAutoGeneratedAmount(line) {
  const { auto_generated_rate, rate, auto_generated_acres, acres } = line;

  return rate === auto_generated_rate && acres === auto_generated_acres;
}

function getAutoGeneratedAmount(line) {
  return [
    round(line.auto_generated_annual_amount, 2),
    round(line.auto_generated_quarterly_amount, 2),
  ];
}

function getAgServicesAmount({ rate, acres, adjustment = 0 }) {
  const annual = round(rate * acres, 2);
  const invoice = round(annual / 4 + adjustment, 2);

  return [annual, invoice];
}

function getOneTimePaymentAmount({ rate, acres, adjustment = 0 }) {
  const invoice = round(rate * acres + adjustment, 2);

  return [undefined, invoice];
}

function aggregateLineItems(acc, [annualTotal, invoiceTotal]) {
  const { annual, quarterly } = acc.lineItems;

  return {
    total: round(acc.total + invoiceTotal, 2),
    lineItems: {
      annual: [...annual, formatUSD(annualTotal)],
      quarterly: [...quarterly, formatUSD(invoiceTotal)],
    },
  };
}

export default {
  namespaced: true,

  state: {
    selectedUser: undefined,
  },

  actions: {
    setSelectedUser({ commit }, selectedUser) {
      commit('updateSelectedUser', selectedUser);
    },
  },

  mutations: {
    updateSelectedUser(state, selectedUser) {
      state.selectedUser = selectedUser;
    },
  },
};

import dayjs from 'dayjs';

export const formatUSD = (value) => {
  value = value || 0;

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return value.toLocaleString(`en-us`, {
    currency: `USD`,
    style: `currency`,
  });
};

// For example: December 16, 2021 7:19 AM
export const formatDateVerbose = (dateString) => {
  return formatDateTime(dateString, 'MMMM D, YYYY h:mm A');
};

export const formatDateTime = (dateString, format) => {
  return dayjs(dateString).format(format);
};

export const getLabel = (label, count) => {
  return count > 1 || count === 0 ? `${label}s` : label;
};

export const formatBalance = (openBalance) => {
  return openBalance < 0
    ? `(${formatUSD(Math.abs(openBalance))})`
    : formatUSD(openBalance);
};

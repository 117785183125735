<template>
  <component
    :is="inPlace ? `div` : `VDialog`"
    v-model="model"
    :fullscreen="fullscreen"
    transition="dialog-bottom-transition"
    :persistent="fullscreen || loading"
    :width="fullscreen ? null : width"
    @click:outside="$emit('click:outside')"
  >
    <v-form v-model="valid" :disabled="disabled || loading">
      <v-card
        :style="fullscreen && { height: `100vh` }"
        class="d-flex flex-column"
        color="white"
      >
        <v-toolbar
          v-if="fullscreen"
          :color="pageToolbar ? `` : `primary`"
          rounded="0"
          short
          class="flex-grow-0"
          style="z-index: 2"
        >
          <v-toolbar-title
            :class="{
              'white--text': !pageToolbar,
              'd-flex align-center': fullscreen,
            }"
          >
            <RecordIcon
              v-if="recordIcon"
              :record="recordIcon"
              :avatar-props="{ size: 36 }"
              class="ml-0 mr-3"
            />
            <div class="text-overline">{{ superTitle }}</div>
            <slot name="title">{{ title }}</slot>
          </v-toolbar-title>
          <v-spacer />
          <slot name="toolbar" />
          <v-btn
            v-if="!unclosable"
            :dark="!pageToolbar"
            icon
            :disabled="loading"
            @click="close"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title v-else class="px-6">
          <RecordIcon
            v-if="recordIcon"
            :record="recordIcon"
            :avatar-props="{ size: 36 }"
            :icon-props="{ size: 22 }"
            class="mr-3"
          />
          <slot name="title">{{ title }}</slot>
          <v-btn v-if="inPlace" icon @click="close"
            ><v-icon>$close</v-icon></v-btn
          >
        </v-card-title>
        <v-container
          :fluid="fluid"
          class="pt-4 overflow-x-auto flex-grow-1 fill-height align-start"
          :class="{ 'px-6': !fullscreen }"
        >
          <div
            style="width: 100%"
            class="mx-auto"
            :style="{ maxWidth: `${width}px` }"
          >
            <slot />
          </div>
        </v-container>
        <v-footer
          v-if="buttons"
          class="justify-end px-6 py-4"
          style="background: #fff; padding: 1rem; z-index: 1"
          :elevation="fullscreen ? 7 : 0"
          rounded="0"
        >
          <slot name="footer" />
          <template v-for="(button, label, i) in buttons">
            <v-btn
              data-testid="modalActions"
              v-if="button"
              :key="label"
              :color="button.color"
              :height="button.height"
              :class="button.class || `m${i > spacerIndex ? `l` : `r`}-4`"
              :loading="
                (button.submitter || i + 1 == buttonsArr.length) && loading
              "
              :outlined="button.outlined"
              :disabled="
                button.disabled ||
                ((i + 1 == buttonsArr.length || button.submitter) && !valid) ||
                (!(i + 1 == buttonsArr.length || button.submitter) && loading)
              "
              :type="
                button.submitter || i + 1 == buttonsArr.length
                  ? `submit`
                  : undefined
              "
              v-bind="button.props"
              @click="clickButton($event, button)"
            >
              <v-icon v-if="button.icon && i < spacerIndex" left
                >mdi-{{ button.icon }}</v-icon
              >
              {{ label }}
              <v-icon v-if="button.icon && i > spacerIndex" right
                >mdi-{{ button.icon }}</v-icon
              >
            </v-btn>
            <v-spacer v-else :key="label" />
          </template>
        </v-footer>
      </v-card>
    </v-form>
  </component>
</template>

<script>
import { VDialog } from 'vuetify/lib';
import RecordIcon from '@/components/misc/RecordIcon';
export default {
  components: { VDialog, RecordIcon },
  props: {
    buttons: Object,
    disabled: Boolean,
    fluid: Boolean,
    fullscreen: { type: Boolean, default: true },
    inPlace: Boolean,
    loading: Boolean,
    pageToolbar: { type: Boolean, default: true },
    recordIcon: String,
    superTitle: String,
    title: String,
    unclosable: {},
    width: { type: [Number, String], default: 718 },
  },
  data: () => ({ model: true, valid: null }),
  computed: {
    buttonsArr: ({ buttons }) => Object.values(buttons),
    spacerIndex: ({ buttonsArr }) => buttonsArr.indexOf(null),
  },
  watch: {
    model() {
      setTimeout(() => this.$emit(`hidden`), 400);
    },
  },
  methods: {
    clickButton(event, { click }) {
      if (!click) return;
      event.preventDefault();
      click();
    },
    close() {
      this.model = false;
    },
  },
};
</script>

import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{staticStyle:{"min-height":"44px"},attrs:{"exact-path":"","to":_vm.item.path}},[_c(VListItemAvatar,{attrs:{"size":"20","rounded":"0"}},[_c(VIcon,[_vm._v("mdi-"+_vm._s(_vm.item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.item.name))]),_c(VListItemAction,[(_vm.item.badgeCount)?_c(VBadge,{attrs:{"data-testid":"navItem-badge","color":"primary","content":_vm.item.badgeCount,"inline":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
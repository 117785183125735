<template>
  <div>
    <Modal
      v-if="showModal"
      ref="modal"
      width="566"
      record-icon="task"
      :fullscreen="false"
      :buttons="{
        Close: { color: `light`, click: close },
        'Save & Close': {
          color: `primary`,
          click: doSave,
          disabled: saveDisabled,
          class: 'confirmation_save',
        },
      }"
    >
      <template #title> Task </template>
      <v-row>
        <div data-testid="sales_close_modal">What Tools did you use?</div>
      </v-row>
      <v-row data-testid="sales_tools">
        <v-col v-for="tool in sales_tools" :key="tool.id" md="6" class="pa-0">
          <v-checkbox
            v-model="checkedTools"
            :value="tool.id"
            :label="tool.name"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <div>Did you Co-Sell this with another person? (Optional)</div>
      </v-row>
      <v-row>
        <v-col
          v-for="coseller in sales_cosellers"
          :key="coseller.id"
          md="6"
          class="pa-0"
        >
          <v-checkbox
            v-model="checkedCoSellers"
            :value="coseller.id"
            :label="coseller.name"
            hide-details
          />
        </v-col>
      </v-row>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/misc/Modal';
import { mapActions, mapState } from 'vuex';

export default {
  name: `TaskCloseConfirmationModal`,
  components: { Modal },
  data: () => ({
    checkedTools: [],
    checkedCoSellers: [],
  }),
  computed: {
    ...mapState('task', ['showModal']),
    ...mapState('crm', ['sales_tools', 'sales_cosellers']),
    saveDisabled: (vm) => vm.checkedTools.length === 0,
  },
  watch: {
    showModal() {
      this.checkedTools = [];
      this.checkedCoSellers = [];
    },
  },
  methods: {
    ...mapActions({
      closeModal: 'task/closeModal',
      completeTask: 'task/completeTask',
    }),
    async doSave() {
      await this.completeTask([this.checkedTools, this.checkedCoSellers]);
    },
    close() {
      this.closeModal();
    },
  },
};
</script>

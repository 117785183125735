import { post } from '../services/api-service';

export default {
  namespaced: true,

  state: {
    error: undefined,
    savingToContract: false,
  },

  actions: {
    async enrollIncentiveProgram(
      { commit },
      { contractId, incentivePrograms }
    ) {
      try {
        commit('updateIncentiveProgram', { savingToContract: true });

        await post(`sow/${contractId}/incentive`, incentivePrograms);

        commit('updateIncentiveProgram', {
          savingToContract: false,
        });
      } catch (e) {
        commit('updateIncentiveProgram', {
          error: 'Error updating contract incentive program',
          savingToContract: false,
        });
      }
    },
  },

  mutations: {
    updateIncentiveProgram(state, payload) {
      Object.assign(state, payload);
    },
  },
};

export default (numberProp) => ({
  computed: {
    propagateVModel: {
      get: ({ value }) => value,
      set(value) {
        const numericValue = +value;
        this.$emit(
          `input`,
          numberProp && this[numberProp] && value && !isNaN(numericValue)
            ? numericValue
            : value
        );
      },
    },
  },

  props: [`value`],
});

<template>
  <tr
    :class="{ gray: highlightedRow == index }"
    style="cursor: pointer"
    @click="$emit('operationClick', item)"
  >
    <td><Highlighter :value="item.operationName" :highlight="input" /></td>
    <td><Highlighter :value="item.city" :highlight="input" /></td>
    <td><Highlighter :value="item.stateName" :highlight="input" /></td>
    <td align="right">{{ formatAcres(item.enrolledAcres) }} acres</td>
    <td><Highlighter :value="item.agronomistName" :highlight="input" /></td>
  </tr>
</template>

<script>
import Highlighter from '@/components/Highlighter';

export default {
  name: 'OperationSearchResults',

  components: {
    Highlighter,
  },

  props: { input: String, item: Object, index: Number, highlightedRow: Number },

  methods: {
    formatAcres(enrolledAcres) {
      return enrolledAcres ? enrolledAcres.toLocaleString() : 0;
    },
  },
};
</script>

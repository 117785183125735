export function deleteBillingGroup({ state, commit }, index) {
  const deletedGroup = state.billingGroups[index];

  const billingGroups = [...state.billingGroups];
  billingGroups.splice(index, 1);

  const newlyAssignable = deletedGroup.fields.map((field) => ({
    ...field,
    assigned: false,
  }));

  commit('updateBillingGroups', {
    billingGroups,
    assignableFields: [...state.assignableFields, ...newlyAssignable],
  });
}

<template>
  <div v-if="snackbar.show">
    <v-alert
      v-if="snackbar.isError"
      class="ma-0 text-body-2"
      data-testid="headerAlert"
      dismissible
      elevation="1"
      @input="closeToast"
      tile
      type="error"
    >
      {{ snackbar.content }}
    </v-alert>
    <v-alert
      v-else
      data-testid="headerAlert"
      :value="snackbar.show"
      :color="snackbar.color"
      border="bottom"
      colored-border
      class="light text-body-2 ma-0"
      rounded="0"
      :icon="snackbar.icon"
      elevation="1"
      dismissible
      @input="closeToast"
    >
      {{ snackbar.content }}
    </v-alert>
  </div>
</template>

<script>
import functions from '@/mixins/functions';
import { mapState } from 'vuex';
export default {
  name: 'HeaderAlert',

  mixins: [functions],

  data: () => ({}),

  computed: {
    ...mapState('app', ['snackbar']),
  },
};
</script>

import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showModal)?_c('Modal',{ref:"modal",attrs:{"width":"566","record-icon":"task","fullscreen":false,"buttons":{
      Close: { color: "light", click: _vm.close },
      'Save & Close': {
        color: "primary",
        click: _vm.doSave,
        disabled: _vm.saveDisabled,
        class: 'confirmation_save',
      },
    }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Task ")]},proxy:true}],null,false,978348048)},[_c(VRow,[_c('div',{attrs:{"data-testid":"sales_close_modal"}},[_vm._v("What Tools did you use?")])]),_c(VRow,{attrs:{"data-testid":"sales_tools"}},_vm._l((_vm.sales_tools),function(tool){return _c(VCol,{key:tool.id,staticClass:"pa-0",attrs:{"md":"6"}},[_c(VCheckbox,{attrs:{"value":tool.id,"label":tool.name,"hide-details":""},model:{value:(_vm.checkedTools),callback:function ($$v) {_vm.checkedTools=$$v},expression:"checkedTools"}})],1)}),1),_c(VRow,{staticClass:"mt-10"},[_c('div',[_vm._v("Did you Co-Sell this with another person? (Optional)")])]),_c(VRow,_vm._l((_vm.sales_cosellers),function(coseller){return _c(VCol,{key:coseller.id,staticClass:"pa-0",attrs:{"md":"6"}},[_c(VCheckbox,{attrs:{"value":coseller.id,"label":coseller.name,"hide-details":""},model:{value:(_vm.checkedCoSellers),callback:function ($$v) {_vm.checkedCoSellers=$$v},expression:"checkedCoSellers"}})],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  /*async mounted () {
		await this.$nextTick();
		console.log(`makeClearButtonUntabbable`, this.label);
		const clearButton = this.$el.querySelector(`.v-input__append-inner .v-input__icon--clear button`);
		if (clearButton) clearButton.tabIndex = -1;
	}*/
  mounted() {
    this.$watch(
      `$refs.input.isDirty`,
      (isDirty) => {
        const { input } = this.$refs;
        if (isDirty && input.clearable)
          input.$el.querySelector(
            `.v-input__append-inner .v-input__icon--clear button`
          ).tabIndex = -1;
      },
      { immediate: true }
    );
  },
};

<template>
  <div v-if="contacts.length">
    <h3>
      Please select the correct customer info below. Please note, that only one
      customer record can be associated with this user account.
    </h3>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="contacts"
      :single-select="true"
      item-key="id"
      show-select
      hide-default-footer
      class="elevation-1"
    >
      <template #top />
    </v-data-table>
  </div>
</template>
<script>
import functions from '@/mixins/functions';
export default {
  name: `ContactSelectionTable`,
  mixins: [functions],
  props: {
    isActive: Boolean,
    items: Array,
  },

  data() {
    return {
      selected: this.items.length === 1 ? this.contacts[0] : [],
      headers: [
        {
          text: 'Account #',
          align: 'start',
          sortable: false,
          value: 'accountNumber',
        },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
      ],
      contacts: this.items ? this.items : [],
    };
  },
  watch: {
    items: {
      handler(newVal, oldVal) {
        this.contacts = newVal;
      },
    },
    selected: {
      handler(newVal, oldVal) {
        this.$emit('update:selected-contact', newVal);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
h3 {
  margin: 1rem;
}
</style>

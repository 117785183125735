import { VCol } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":_vm.cols,"lg":_vm.lg}},[_c(_vm.textarea
        ? "VTextarea"
        : _vm.autocomplete
        ? _vm.alwaysHasValue
          ? "VSelect"
          : "VAutocomplete"
        : "VTextField",_vm._g(_vm._b({ref:"input",tag:"component",class:{
      'body-2': _vm.tableInput,
      'table-input-disabled': _vm.tableInput && _vm.disabled,
    },style:(_vm.tableInput && { minWidth: "150px" }),attrs:{"error":_vm.error || (_vm.$refs.input && !_vm.$refs.input.$refs.input.validity.valid),"background-color":_vm.backgroundColor,"color":_vm.tableInput && _vm.disabled ? "grey lighten-5" : "","filled":!_vm.solo && !_vm.tableInput,"solo":_vm.solo,"outlined":_vm.outlined || _vm.tableInput,"hide-details":_vm.hideDetails,"label":_vm.label,"disabled":_vm.disabled,"single-line":_vm.singleLine || _vm.tableInput,"prefix":_vm.prefix,"autocomplete":_vm.autocompleteAttr},scopedSlots:_vm._u([_vm._l((_vm.$slots),function(_,name){return {key:name,fn:function(){return [_vm._t(name)]},proxy:true}}),{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.label)),(_vm.required)?_c('span',{staticClass:"text-subtitle-1 error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.propagateVModel),callback:function ($$v) {_vm.propagateVModel=$$v},expression:"propagateVModel"}},'component',Object.assign({}, {type: _vm.type},
      (_vm.autocomplete && Object.assign({}, {noDataText: _vm.autocomplete.loading ? "Searching.." : "No results",
        disabled: _vm.autocomplete.loading || _vm.autocomplete.disabled,
        itemText: "name",
        itemValue: "id",
        returnObject: true},
        _vm.autocomplete)),
      {rules: (_vm.required
          ? [
              function (v) { return typeof v === "number" && !_vm.autocomplete ? !isNaN(v) : !!v; } ]
          : []).concat( (_vm.decimal ? [function (v) { return (("" + v).split(".")[1] || "").length < 3; }] : []),
        (_vm.type == "tel"
          ? [function (v) { return !v || v.replace(/\D/g, "").length == 10; }]
          : []),
        _vm.rules )},
      _vm.$attrs),false),
      Object.fromEntries(
        Object.entries(_vm.$listeners).filter(function (ref) {
            var event = ref[0];

            return event != "input";
})
      )
    ))],1)}
var staticRenderFns = []

export { render, staticRenderFns }
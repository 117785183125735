<template>
  <v-avatar v-bind="avatarProps" :color="records[record].color">
    <v-icon v-bind="iconProps" dark>mdi-{{ records[record].icon }}</v-icon>
  </v-avatar>
</template>

<script>
export default {
  name: `RecordIcon`,
  props: {
    avatarProps: Object,
    iconProps: Object,
    record: { type: String, required: true },
  },
  data: () => ({
    records: {
      billing: { color: `#33691E`, icon: `mail` },
      comment: { color: `#7CB1FF`, icon: `message-reply-text` },
      contact: { color: `#9DD6FF`, icon: `contacts-outline` },
      contract: { color: `#B2B7BF`, icon: `script-text-outline` },
      email: { color: `#629749`, icon: `email` },
      flag: { color: `#c00000`, icon: `flag-outline` },
      invoice: { color: `#EABA8D`, icon: `mail` },
      operation: { color: `#98A2FF`, icon: `domain` },
      payment: { color: `#2BCEC4`, icon: `currency-usd` },
      run: { color: `#33691E`, icon: `run` },
      scouting: { color: `#629749`, icon: `play-circle-outline` },
      target: { color: `#FF8181`, icon: `record-circle-outline` },
      task: { color: `#6DD19B`, icon: `clipboard-check` },
      user: { color: `#353535`, icon: `account-circle` },
    },
  }),
};
</script>

import { get } from '../services/api-service';

export default {
  namespaced: true,

  state: {
    selectedView: undefined,
    loading: false,
    operations: [],
  },

  getters: {
    operations: ({ operations }) => {
      return operations;
    },
  },

  actions: {
    async loadOperations({ state, commit, dispatch }) {
      commit('updateOperations', { loading: true, operations: [] });

      try {
        const operations = await get('operations', state.selectedView);

        commit('updateOperations', { loading: false, operations });
      } catch (e) {
        dispatch('app/showError', 'Error retrieving operations', {
          root: true,
        });
        commit('updateOperations', { loading: false });
      }
    },
    setSelectedView({ commit }, selectedView) {
      commit('updateSelectedView', selectedView);
    },
  },

  mutations: {
    updateOperations(state, payload) {
      Object.assign(state, payload);
    },
    updateSelectedView(state, selectedView) {
      state.selectedView = selectedView;
    },
  },
};

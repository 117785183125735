export default {
  namespaced: true,

  state: {
    selectedTargetView: undefined,
    showFilters: false,
    filters: {
      acreageMin: 0,
      acreageMax: 0,
      agronomists: [],
      operations: [],
      salesLanes: [],
      states: [],
      taskDueDates: [],
    },
  },

  getters: {
    getTargetSalesLanes: (_, getters, rootState) => {
      return rootState.crm.target_status.filter(
        ({ target_parent_id }) => target_parent_id
      );
    },
    getSelectedSalesLanes: (state) => {
      return state.filters.salesLanes;
    },
  },

  actions: {
    toggleTargetFilters({ commit }) {
      commit('toggleShowFilters');
    },
    setTargetFilters({ commit }, { property, value }) {
      commit('updateTargetFilters', { property, value });
    },
    setSelectedTargetView({ commit }, selectedTargetView) {
      commit('updateSelectedTargetView', selectedTargetView);
    },
  },

  mutations: {
    updateTargetFilters(state, { property, value }) {
      const filterValues = Array.isArray(value) ? [...value] : value;

      state.filters[property] = filterValues;
    },
    updateSelectedTargetView(state, selectedTargetView) {
      state.selectedTargetView = selectedTargetView;
    },
    toggleShowFilters(state) {
      state.showFilters = !state.showFilters;
    },
  },
};

<template>
  <span
    v-html="
      value
        ? value.replace(
            new RegExp(
              highlight.replace(/[.*+\-?^${}()|[\]\\]/g, `\\$&`),
              `gi`
            ),
            `<mark>$&</mark>`
          )
        : ''
    "
  />
</template>

<script>
export default {
  name: 'Highlighter',
  props: {
    value: { type: String },
    highlight: { type: String, required: true },
  },
};
</script>

<template>
  <v-col :cols="cols" :lg="lg">
    <component
      :is="
        textarea
          ? `VTextarea`
          : autocomplete
          ? alwaysHasValue
            ? `VSelect`
            : `VAutocomplete`
          : `VTextField`
      "
      ref="input"
      v-model="propagateVModel"
      :error="error || ($refs.input && !$refs.input.$refs.input.validity.valid)"
      :background-color="backgroundColor"
      :color="tableInput && disabled ? `grey lighten-5` : ``"
      :filled="!solo && !tableInput"
      :solo="solo"
      :outlined="outlined || tableInput"
      :hide-details="hideDetails"
      :label="label"
      :disabled="disabled"
      :single-line="singleLine || tableInput"
      :prefix="prefix"
      :autocomplete="autocompleteAttr"
      :class="{
        'body-2': tableInput,
        'table-input-disabled': tableInput && disabled,
      }"
      :style="tableInput && { minWidth: `150px` }"
      v-bind="{
        // TODO: fix clearable assigning null value -->
        // clearable: !alwaysHasValue,
        type,
        ...(autocomplete && {
          noDataText: autocomplete.loading ? `Searching..` : `No results`,
          disabled: autocomplete.loading || autocomplete.disabled,
          itemText: `name`,
          itemValue: `id`,
          returnObject: true,
          ...autocomplete,
        }),
        rules: [
          ...(required
            ? [
                (v) =>
                  typeof v === `number` && !autocomplete ? !isNaN(v) : !!v,
              ]
            : []),
          ...(decimal ? [(v) => (`${v}`.split(`.`)[1] || ``).length < 3] : []),
          ...(type == `tel`
            ? [(v) => !v || v.replace(/\D/g, ``).length == 10]
            : []),
          ...rules,
        ],
        ...$attrs,
      }"
      v-on="
        Object.fromEntries(
          Object.entries($listeners).filter(([event]) => event != `input`)
        )
      "
    >
      <template v-for="(_, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
      <template #label
        >{{ label
        }}<span v-if="required" class="text-subtitle-1 error--text"
          >*</span
        ></template
      >
    </component>
  </v-col>
</template>

<script>
import propagateVModel from '@/mixins/propagateVModel';
import { VAutocomplete, VSelect, VTextField, VTextarea } from 'vuetify/lib';
import makeClearButtonUntabbable from '@/mixins/makeClearButtonUntabbable';

export default {
  name: `FormInput`,

  components: { VAutocomplete, VTextField, VTextarea, VSelect },

  mixins: [propagateVModel(`decimal`), makeClearButtonUntabbable],

  props: {
    alwaysHasValue: Boolean,
    autocomplete: [Object, Boolean],
    autocompleteAttr: String,
    cols: [String, Number],
    lg: Number,
    decimal: Boolean,
    disabled: Boolean,
    error: Boolean,
    hideDetails: { type: [Boolean, String], default: true },
    label: String,
    outlined: Boolean,
    required: Boolean,
    rules: { type: Array, default: () => [] },
    singleLine: Boolean,
    solo: Boolean,
    tableInput: Boolean,
    textarea: [Object, Boolean],
    type: String,
    prefix: String,
  },

  computed: {
    backgroundColor: (vm) => {
      if (vm.disabled) {
        return `grey lighten-5`;
      }

      return vm.tableInput ? `white` : ``;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-textarea::v-deep .v-input__append-inner {
  position: absolute;
  top: 0;
  right: 12px;
}
.table-input-disabled::v-deep fieldset {
  border-color: #e0e0e0 !important;
}
</style>

/*eslint-env node */
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import { Ripple } from 'vuetify/lib/directives';

// https://github.com/vuetifyjs/vuetify/issues/12224
Vue.use(Vuetify, { directives: { Ripple } });

// noinspection JSUnusedGlobalSymbols
export default new Vuetify({
  icons: { values: { sort: `mdi-sort-variant` } },
  theme: {
    options: {
      customProperties: true,
      minifyTheme: (css) =>
        process.env.NODE_ENV !== `development`
          ? css.replace(/\r\n|\r|\n/g, ``)
          : css,
    },
    themes: {
      light: {
        primary: `#629749`,
        primary_medium: `#33691E`,
        primary_dark: `#003D00`,
        primary_background: `#D2E7D2`,
        secondary: `#E6A33D`,
        secondary_background: `#FEF0CD`,
        dark: `#414141`,
        light: `#f8f8f8`,
        error: `#B00020`,
        error_text: `#890019`,
        error_background: `#F6E0E4`,
        success: `#4CAF50`,
        success_text: `#003D00`,
        success_background: `#E7EDE4`,
        warning: `#C49000`,
        warning_text: `#D37E00`,
        warning_background: `#FFF2E0`,
        info: `#2979FF`,
        info_text: `#0051DA`,
        info_background: `#E5EFFF`,
        Warm: `#A88249`,
        Hot: `#963E4E`,
        Closed_Lost: `#5F5F5F`,
        secondary_medium: `#5F5F5F`,
        gray: {
          base: `#EAEAEA`,
          lighten1: `#F8F8F8`,
        },
        alert_bg: `#FFF2E0`,
        alert_icon: `#D37E00`,
        success_icon: `#003D00`,
      },
    },
  },
});

import Auth from '@aws-amplify/auth';
import functions from '@/mixins/functions';
import { getEditPermissions } from './edit-permission';

const { methods } = functions;

const USER_COGNITO_ID_KEY = `${
  process.env.VUE_APP_PROD ? `prod` : `qa`
}_aws_cognito_id`;

export default {
  namespaced: true,
  state: { aws_cognito_id: ``, impersonation_id: 0, customer: {} },
  getters: {
    me: (state, getters, { crm }) => {
      return (
        crm.user.find(({ id }) => state.impersonation_id === id) ||
        getters.meIgnoringImpersonation
      );
    },

    isABM: (state, getters, rootState, { 'crm/USER_GROUP': USER_GROUP }) => {
      return getters.me?.user_group_id == USER_GROUP.ABM;
    },

    isASL: (state, getters, rootState, { 'crm/USER_GROUP': USER_GROUP }) => {
      return getters.me?.user_group_id == USER_GROUP.ASL;
    },

    isAdmin: (state, getters, rootState, { 'crm/USER_GROUP': USER_GROUP }) => {
      return getters.me?.user_group_id == USER_GROUP.ADMIN;
    },

    isValidCustomerPortalUser: (
      state,
      getters,
      rootState,
      { 'crm/USER_GROUP': USER_GROUP }
    ) => {
      return getters.me?.user_group_id == USER_GROUP.CUSTOMER;
    },

    meIgnoringImpersonation: (state, getters, { crm }) => {
      return crm.user.find(
        (user) => state.aws_cognito_id === user[USER_COGNITO_ID_KEY]
      );
    },

    canGloballyEdit: (
      state,
      { me },
      rootState,
      { 'crm/USER_GROUP': USER_GROUP }
    ) =>
      [
        USER_GROUP.RBD,
        USER_GROUP.ADMIN,
        USER_GROUP.ASL,
        USER_GROUP.NonAcctAdmin,
      ].includes(me.user_group_id),
    autoAgronomistId: (state, { me }, rootState, rootGetters) =>
      me.user_group_id == rootGetters[`crm/USER_GROUP`].ADMIN
        ? 2 /* Sean */
        : me.id,
    impersonationId: ({ impersonation_id }, { me }) =>
      impersonation_id || me.id,
    permissionCol: (state, { me }, rootState, rootGetters) => {
      const out = `${rootGetters[`crm/UserGroupById`](me.user_group_id)
        .name.split(` `)
        .pop()
        .toLowerCase()}_id`;
      return out;
    },
    canEditRecord(state, getters, rootState, rootGetters) {
      return (record) => getEditPermissions(rootState, getters, record);
    },
    hasRole(_, { me }) {
      return (...roles) => roles.includes(me.user_group_id);
    },
  },

  actions: {
    async refresh() {
      await Auth.currentSession();
    },
    async getMe({ commit }) {
      commit(
        `setAwsUuid`,
        (await Auth.currentAuthenticatedUser()).attributes.sub
      );
    },
    async setImpersonation({ dispatch, commit }, impersonationId) {
      commit('setImpersonationId', impersonationId);
    },
    async getCustomer({ commit }) {
      commit(
        `setCustomer`,
        (await methods.crmRequest(`crud/customer-accounts-view`, {}, `GET`))
          .value[0]
      );
    },
  },

  mutations: {
    setAwsUuid: (state, uuid) => (state.aws_cognito_id = uuid),
    setImpersonationId: (state, impersonation_id) =>
      (state.impersonation_id = impersonation_id),
    setCustomer: (state, customer) => (state.customer = customer),
  },
};

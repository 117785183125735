import Auth from '@aws-amplify/auth';

export async function getFeatureFlags() {
  return get('feature-flags');
}

export function getHost() {
  const isProd = process.env.VUE_APP_STAGE === 'prod';
  const isDr = process.env.VUE_APP_STAGE === 'dr';

  if (isProd) return `https://terraframing.advancedagrilytics.com`;
  if (isDr) return `https://terraframing-dr.advancedagrilytics.com`;
  return `https://terraframing-test.advancedagrilytics.com`;
}

export async function get(path) {
  const response = await fetch(`${getHost()}/api/${path}`, {
    method: 'GET',
    headers: await getHeaders(),
  });

  try {
    return await response.json();
  } catch (e) {
    throw new Error(`TFS error ${response.status}`);
  }
}

async function getHeaders() {
  try {
    const session = await Auth.currentSession();
    return { Authorization: session.getIdToken().getJwtToken() };
  } catch (e) {
    return {}; // not logged in
  }
}

import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';

import auth from './auth.module';
import crm from './crm.module';
import app from './app.module';
import featureFlags from './feature-flags.module';
import task from './task.module';
import dashboard from './dashboard.module';
import targets from './targets.module';
import billing from './billing.module';
import account from './account.module';
import accounts from './accounts.module';
import operation from './operation.module';
import invoice from './invoice.module';
import billingGroups from './billing-group.module';
import contacts from './contacts.module';
import contracts from './contracts.module';
import operations from './operations.module';
import target from './target.module';

Vue.use(Vuex);

export default createStore(Vuex.Store, {
  strict: process.env.VUE_APP_STAGE === 'dev',
  modules: {
    auth,
    crm,
    app,
    featureFlags,
    task,
    dashboard,
    targets,
    billing,
    account,
    accounts,
    operation,
    invoice,
    billingGroups,
    contacts,
    operations,
    contracts,
    target,
  },
});

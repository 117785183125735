import { get } from '../services/api-service';

export default {
  namespaced: true,

  state: {
    selectedView: undefined,
    loading: false,
    contacts: [],
  },

  getters: {
    contacts: ({ contacts }) => {
      return contacts;
    },
  },

  actions: {
    async loadContacts({ state, commit, dispatch }) {
      commit('updateContacts', { loading: true, contacts: [] });

      try {
        const contacts = await get('contacts', state.selectedView);

        commit('updateContacts', { loading: false, contacts });
      } catch (e) {
        dispatch('app/showError', 'Error retrieving contacts', {
          root: true,
        });
        commit('updateContacts', { loading: false });
      }
    },
    setSelectedView({ commit }, selectedView) {
      commit('updateSelectedView', selectedView);
    },
  },

  mutations: {
    updateContacts(state, payload) {
      Object.assign(state, payload);
    },
    updateSelectedView(state, selectedView) {
      state.selectedView = selectedView;
    },
  },
};

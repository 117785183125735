<template>
  <v-avatar :color="loading ? null : `#285817`" :size="size" dark>
    <v-skeleton-loader v-if="loading" type="avatar" />
    <v-img
      v-else-if="imgSrc"
      :src="
        imgSrc && ~imgSrc.indexOf(`https`) ? imgSrc : getS3PictureUrl(imgSrc)
      "
    />
    <span
      v-else
      class="white--text"
      :style="{ fontSize: `${Math.min(size / 2, 13)}px` }"
    >
      {{
        name
          .toUpperCase()
          .split(` `)
          .slice(0, 2)
          .map(([l]) => l)
          .join(``)
      }}
    </span>
  </v-avatar>
</template>

<script>
import functions from '@/mixins/functions';

export default {
  name: `Avatar`,
  mixins: [functions],
  props: {
    name: String,
    imgSrc: { type: String, default: `` },
    size: { type: [Number, String], default: 40 },
  },
  computed: {
    color({ name }) {
      const colors = [
        `0097a7`,
        `0288d1`,
        `1976d2`,
        `303f9f`,
        `388e3c`,
        `455a64`,
        `512da8`,
        `5d4037`,
        `616161`,
        `689f38`,
        `7b1fa2`,
        `d32f2f`,
        `e64a19`,
        `f57c00`,
        `fbc02d`,
        `ffa000`,
      ];
      return `#${
        name
          ? colors[
              Math.floor(
                ((name.toUpperCase().charCodeAt(0) - 65) / 26) * colors.length
              )
            ]
          : `000`
      }`;
    },
    loading: (vm) => !vm.imgSrc && !vm.name,
  },
};
</script>

import { saveBillingGroups } from './billing-group/save-action';
import { loadBillingGroups } from './billing-group/load-action';
import { convertTarget } from './billing-group/convert-target-action';
import { unassignField } from './billing-group/unassign-field-action';
import { assignFieldToBillingGroup } from './billing-group/assign-field-action';
import { assignFieldsToBillingGroup } from './billing-group/assign-fields-action';
import { deleteBillingGroup } from './billing-group/delete-billing-group-action';
import { addBillingGroup } from './billing-group/add-billing-group-action';
import { getBillingCycleFromBillDate } from '@/utils/util';

const INITIAL_STATE = {
  loading: false,
  showError: false,
  saving: false,
  isComplete: false,

  billingCycle: undefined,
  rotationSchedule: undefined,
  isFirstMonthEditable: undefined,
  additionalBillingInfo: undefined,
  firstBillingMonth: undefined,

  elibibleUsers: undefined,
  billingGroups: [],
  unassignedFields: [],
  assignableFields: [],
  addresses: {},

  pdfUrl: undefined,
};

export default {
  namespaced: true,

  state: {
    ...INITIAL_STATE,
  },

  getters: {
    assignedFields({ billingGroups }) {
      return billingGroups.flatMap((group) =>
        group.fields.map((field) => field)
      );
    },
    defaultBillingGroup({ billingGroups }) {
      return billingGroups.find(
        ({ groupName }) => groupName === 'Default Billing Group'
      );
    },
    billingCycle({ billingCycle, firstBillingMonth }) {
      return billingCycle !== undefined
        ? billingCycle
        : getBillingCycleFromBillDate(firstBillingMonth);
    },
    isMissingAddresses({ addresses }) {
      return Object.values(addresses).some(
        ({ address1, city, state, zip }) =>
          address1?.trim().length < 2 ||
          city?.trim().length < 2 ||
          state?.trim().length < 2 ||
          zip?.trim().length < 2
      );
    },
  },

  actions: {
    addBillingGroup,
    assignFieldToBillingGroup,
    assignFieldsToBillingGroup,
    convertTarget,
    deleteBillingGroup,
    loadBillingGroups,
    reset: ({ commit }) => commit('updateBillingGroups', INITIAL_STATE),
    saveBillingGroups,
    unassignField,
    updateBillingGroup({ commit }, payload) {
      commit('updateBillingGroups', payload);
    },
    updateAddress({ state, commit }, { type, updates }) {
      const { addresses } = state;
      const thisAddress = addresses[type];
      const newLocal = {
        addresses: {
          ...addresses,
          [type]: {
            ...thisAddress,
            ...updates,
          },
        },
      };
      commit('updateBillingGroups', newLocal);
    },
  },

  mutations: {
    updateBillingGroups(state, payload) {
      Object.assign(state, payload);
    },
  },
};

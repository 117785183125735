<template>
  <v-list-item exact-path :to="item.path" style="min-height: 44px">
    <v-list-item-avatar size="20" rounded="0">
      <v-icon>mdi-{{ item.icon }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-title>{{ item.name }}</v-list-item-title>
    <v-list-item-action>
      <v-badge
        v-if="item.badgeCount"
        data-testid="navItem-badge"
        color="primary"
        :content="item.badgeCount"
        inline
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: `NavItem`,
  props: { item: { type: Object, required: true } },
};
</script>

<style lang="scss" scoped>
.v-list-item--active {
  background: var(--v-primary-base);
}
</style>

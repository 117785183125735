import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    [_vm.USER_GROUP.ADMIN, _vm.USER_GROUP.RBD].includes(
      _vm.meIgnoringImpersonation.user_group_id
    )
  )?_c(VAutocomplete,{attrs:{"data-testid":"impersonation","dark":"","items":_vm.user
      .filter(function (ref) {
        var status = ref.status;

        return status;
})
      .map(function (u) { return ({
        value: u.id,
        text: ((_vm.UserGroupById(u.user_group_id).name) + ": " + (u.first_name) + " " + (u.last_name)),
      }); })
      .sort(function (a, b) { return a.text.localeCompare(b.text); }),"value":_vm.impersonationId},on:{"input":_vm.saveImpersonation}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-autocomplete
    data-testid="impersonation"
    v-if="
      [USER_GROUP.ADMIN, USER_GROUP.RBD].includes(
        meIgnoringImpersonation.user_group_id
      )
    "
    dark
    :items="
      user
        .filter(({ status }) => status)
        .map((u) => ({
          value: u.id,
          text: `${UserGroupById(u.user_group_id).name}: ${u.first_name} ${
            u.last_name
          }`,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    "
    :value="impersonationId"
    @input="saveImpersonation"
  />
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { setApiImpersonationId, get } from '@/services/api-service';

export default {
  name: `Impersonator`,
  computed: {
    ...mapGetters('auth', ['me', 'impersonationId', 'meIgnoringImpersonation']),
    ...mapGetters('crm', ['USER_GROUP', 'UserGroupById']),
    ...mapState('crm', ['user']),
  },
  methods: {
    ...mapActions({
      setImpersonation: 'auth/setImpersonation',
      setConfig: 'crm/setConfig',
    }),
    async saveImpersonation(impersonationId) {
      await this.setImpersonation(impersonationId);

      const response = await get('config');
      await this.setConfig(response.value);
    },
  },
  watch: {
    async impersonationId(newVal) {
      setApiImpersonationId(newVal);
    },
  },
};
</script>

import { post } from '../services/api-service';

export default {
  namespaced: true,

  state: {
    error: undefined,
    netSuiteDateCreated: undefined,
    savingToAccounting: false,
  },

  actions: {
    async reset({ commit }) {
      commit('updateInvoice', {
        netSuiteDateCreated: undefined,
        error: undefined,
      });
    },
    async showNoAccountNumberError({ commit }) {
      commit('updateInvoice', {
        error: 'Contact does not have an Account Number',
      });
    },
    async sendInvoiceToAccounting({ commit }, { invoiceId, location }) {
      try {
        commit('updateInvoice', { savingToAccounting: true });

        const { netSuiteDateCreated } = await post(
          `invoice/${invoiceId}/accounting`,
          { location }
        );

        commit('updateInvoice', {
          netSuiteDateCreated,
          savingToAccounting: false,
        });
      } catch (e) {
        commit('updateInvoice', {
          error: 'Error creating invoice in NetSuite',
          savingToAccounting: false,
        });
      }
    },
  },

  mutations: {
    updateInvoice(state, payload) {
      Object.assign(state, payload);
    },
  },
};

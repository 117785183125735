import { get } from '@/services/api-service';

export async function loadBillingGroups({ commit }, contractId) {
  try {
    commit('updateBillingGroups', { loading: true });

    const response = await get(`sow/${contractId}/billing`);

    commit('updateBillingGroups', {
      additionalBillingInfo: response.additionalBillingInfo,
      billingCycle: response.billingCycle,
      billingGroups: response.billingGroups,
      eligibleUsers: response.contacts,
      firstBillingMonth: response.firstBillingMonth,
      isFirstMonthEditable: response.isFirstMonthEditable,
      loading: false,
      rotationSchedule: response.rotationSchedule,
      unassignedFields: response.unassignedFields,
      addresses: response.addresses,
    });
  } catch (e) {
    commit('updateBillingGroups', { loading: false, showError: true });
  }
}

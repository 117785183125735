import functions from '@/mixins/functions';
import { get, post, put } from '@/services/api-service';

const {
  methods: { makeFilter },
} = functions;

export async function saveDone(tools, cosellers, task) {
  await Promise.all(
    tools.map((toolId) => {
      post('crud/task-completion-tools', {
        task_id: task.id,
        tool_id: toolId,
      });
    })
  );

  await Promise.all(
    cosellers.map((cosellerId) => {
      post('crud/task-completion-cosellers', {
        task_id: task.id,
        coseller_id: cosellerId,
      });
    })
  );

  await put(`crud/task/${task.id}`, {
    done: 1,
  });
}

export async function fetchCommentsByTaskId(task_id) {
  const response = await get(`crud/task-comment`, {
    filters: [
      [`status`, 1],
      [`task_id`, task_id],
    ].map((entry) => makeFilter(...entry)),
    amount: `all`,
    sortBy: [`date_created`],
    sortDesc: [false],
  });
  const comments = response.value;
  return comments;
}

export async function fetchLikesForComments(comment_ids = []) {
  if (!comment_ids.length) {
    return [];
  }
  const likes = (
    await get(`crud/task-comment-like`, {
      filters: [makeFilter(`task_comment_id`, comment_ids, `IN`)],
      amount: `all`,
    })
  ).value;
  return likes;
}

/**
 * Queries the database for tools associated with the provided
 * task id.
 * @param { Number } taskId - Id of the task
 * @returns { Promise< Array< Object > > } an array of task objects if found or an empty array
 */
export async function fetchDetailsByTaskId(taskId) {
  return await Promise.all([
    getTaskDetails('tools', taskId),
    getTaskDetails('cosellers', taskId),
  ]).then((details) => details.map((detail) => detail.value));
}

function getTaskDetails(type, taskId) {
  return get(`crud/task-completion-${type}`, {
    filters: [makeFilter('task_id', taskId)],
  });
}

export function assignFieldToBillingGroup(
  { state, commit },
  { field, assignedGroup, type }
) {
  const billingGroups = state.billingGroups.map((billingGroup) => {
    return billingGroup === assignedGroup
      ? addFieldToBillingGroup(billingGroup, field, type)
      : removeFieldFromBillingGroup(billingGroup, field, type);
  });

  const unassignedFields = removeField(state.unassignedFields, field);
  const assignableFields = removeField(state.assignableFields, field);

  commit('updateBillingGroups', {
    billingGroups,
    unassignedFields,
    assignableFields,
  });
}

const addFieldToBillingGroup = (billingGroup, field, type) => {
  if (type === undefined) {
    return {
      ...billingGroup,
      fields: [...billingGroup.fields, field],
      soilFields: [...billingGroup.soilFields, field],
    };
  }

  if (type === 'soil') {
    return {
      ...billingGroup,
      soilFields: [...billingGroup.soilFields, field],
    };
  }

  return {
    ...billingGroup,
    fields: [...billingGroup.fields, field],
  };
};

const removeFieldFromBillingGroup = (billingGroup, field, type) => {
  if (type === undefined) {
    return {
      ...billingGroup,
      fields: removeField(billingGroup.fields, field),
      soilFields: removeField(billingGroup.soilFields, field),
    };
  }

  if (type === 'soil') {
    return {
      ...billingGroup,
      soilFields: removeField(billingGroup.soilFields, field),
    };
  }

  return {
    ...billingGroup,
    fields: removeField(billingGroup.fields, field),
  };
};

function removeField(fields, field) {
  return fields.filter((assignedField) => assignedField.aaid !== field.aaid);
}

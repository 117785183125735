export function getEditPermissions(rootState, getters, record) {
  const { me: loggedInUser, permissionCol, canGloballyEdit } = getters;
  const { crm } = rootState;

  return (
    canGloballyEdit ||
    isAgronomist(record, loggedInUser) ||
    isPartnerRecord(record, crm.user_partner) ||
    isABM(record, permissionCol, loggedInUser)
  );
}

function isPartnerRecord(record, partners = []) {
  const agronomistId = record.agronomist_id || record.agronomistId;

  return partners
    .map((partner) => partner.partner_user_id)
    .includes(agronomistId);
}

function isAgronomist(record, loggedInUser) {
  return (
    record.agronomist_id === loggedInUser.id ||
    record.agronomistId === loggedInUser.id ||
    record.secondary_agronomist_id === loggedInUser.id ||
    record.lead_agronomist_id === loggedInUser.id ||
    record.leadAgronomistId === loggedInUser.id
  );
}

function isABM(record, permissionCol, loggedInUser) {
  return (
    record.areaBusinessManagerId === loggedInUser.id ||
    record[permissionCol] === loggedInUser.id ||
    record.agronomist?.area_business_manager === loggedInUser.id ||
    record.secondary_agronomist?.area_business_manager === loggedInUser.id
  );
}

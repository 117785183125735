import Vue from 'vue';
import Rollbar from 'rollbar';
import Auth from '@aws-amplify/auth';
import VueGtm from '@gtm-support/vue2-gtm';
import '@aws-amplify/ui-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import '@/assets/scss/main.scss';

const region = 'us-east-1';
const isProd = process.env.VUE_APP_STAGE === 'prod';
const isQa = process.env.VUE_APP_STAGE === 'qa';
const isDr = process.env.VUE_APP_STAGE === 'dr';

const googleTagManager = {
  dev: {
    id: 'GTM-W9CZHCG',
    queryParams: {
      gtm_auth: '7wIbkgeKTq0qH1px8zgSMA',
      gtm_preview: 'env-14',
      gtm_cookies_win: 'x',
    },
    debug: true,
  },
  qa: {
    id: 'GTM-W9CZHCG',
    queryParams: {
      gtm_auth: '1UOt28OISDlntFOn4PHK9A',
      gtm_preview: 'env-3',
      gtm_cookies_win: 'x',
    },
    debug: true,
  },
  prod: {
    id: 'GTM-W9CZHCG',
    queryParams: {
      gtm_auth: '6GZhWC2uJier3tsgpKfSoQ',
      gtm_preview: 'env-1',
      gtm_cookies_win: 'x',
    },
    debug: false,
  },
  dr: {
    id: 'GTM-W9CZHCG',
    queryParams: {
      gtm_auth: '1UOt28OISDlntFOn4PHK9A',
      gtm_preview: 'env-3',
      gtm_cookies_win: 'x',
    },
    debug: false,
  },
};

Auth.configure({
  region,
  userPoolId: `${region}_${
    isProd ? 'JL0xgfrhp' : isDr ? 'Gwp33p7bi' : '2Sm5d7tpQ'
  }`,
  userPoolWebClientId: isProd
    ? '64ub2fr7trtiinb7h44v1upodq'
    : isDr
    ? 'dqm4ceajotbrsmm6p17ec8j1j'
    : 'ofu9sn3vdfdsqj1v911b0n0rs',
  mandatorySignIn: false,
});

Vue.config.productionTip = false;

Vue.prototype.$rollbar = new Rollbar({
  accessToken: '06db8f9ef9c342ad9fed67dfff42c87f',
  environment: process.env.VUE_APP_STAGE,
  enabled: isProd || isQa,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

Vue.use(VueGtm, {
  ...googleTagManager[process.env.VUE_APP_STAGE || 'dev'],
  defer: false,
  compatibility: false,
  nonce: '2726c7f26c',
  enabled: true,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.configure({
    payload: {
      code_version: process.env.VUE_APP_CODE_VERSION,
      person: store.getters['auth/meIgnoringImpersonation'],
    },
  });

  vm.$rollbar.error(err);
  throw err;
};

new Vue({
  router,
  el: '#app',
  store,
  vuetify,
  data: { modals: [] },
  render: (h) => h(App),
});

module.exports = [
  {
    name: `Dashboard`,
    icon: `view-dashboard-outline`,
    roles: ['ABM', 'ASL', 'PA'],
    path: '/dashboard',
  },
  {
    name: `Tasks`,
    icon: `clipboard-check-outline`,
    roles: ['RBD', 'ABM', 'PA', 'ADMIN', 'ACCOUNTING', 'ASL', 'NonAcctAdmin'],
    path: '/tasks',
  },
  {
    name: `Targets`,
    icon: `record-circle-outline`,
    roles: ['RBD', 'ABM', 'PA', 'ADMIN', 'ACCOUNTING', 'ASL', 'NonAcctAdmin'],
    path: '/targets',
  },
  {
    name: `Operations`,
    icon: `domain`,
    roles: ['RBD', 'ABM', 'PA', 'ADMIN', 'ACCOUNTING', 'ASL', 'NonAcctAdmin'],
    path: '/operations',
  },
  {
    name: `Contacts`,
    icon: `contacts-outline`,
    roles: ['RBD', 'ABM', 'PA', 'ADMIN', 'ACCOUNTING', 'ASL', 'NonAcctAdmin'],
    path: '/contacts',
  },
  {
    name: `Scouting`,
    icon: `map`,
    roles: ['RBD', 'ABM', 'PA', 'ADMIN', 'ACCOUNTING', 'ASL', 'NonAcctAdmin'],
    path: '/scouting',
  },
  {
    name: `Accounting`,
    icon: `bank-outline`,
    roles: ['RBD', 'ABM', 'PA', 'ADMIN', 'ACCOUNTING', 'ASL', 'NonAcctAdmin'],
    path: '/accounting',
    children: [
      {
        name: `Accounts`,
        icon: `bank-outline`,
        roles: [
          'RBD',
          'ABM',
          'PA',
          'ADMIN',
          'ACCOUNTING',
          'ASL',
          'NonAcctAdmin',
        ],
        path: '/accounts',
      },
      {
        name: `Acre Report`,
        icon: `clipboard-text-multiple`,
        roles: ['ADMIN', 'ACCOUNTING'],
        path: '/acrechangereport',
      },
      {
        name: `Billing`,
        icon: `inbox-full-outline`,
        roles: ['RBD', 'ABM', 'PA', 'ADMIN', 'ACCOUNTING', 'ASL'],
        path: '/billing',
      },
      {
        name: `Invoices`,
        icon: `clipboard-text-multiple`,
        roles: ['RBD', 'ADMIN', 'ACCOUNTING'],
        path: '/invoices',
      },
      {
        name: `Payments`,
        icon: `currency-usd`,
        roles: ['RBD', 'ADMIN', 'ACCOUNTING'],
        path: '/payments',
      },
    ],
  },
];

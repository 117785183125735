import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"space-between","align":"center"}},[_c(VCol,[_c('PublicImage',{staticStyle:{"max-height":"56px"},attrs:{"src":"AA Light Horizontal"}})],1),_c(VCol,[_c('div',{staticClass:"d-flex justify-end"},[(_vm.me)?_c(VMenu,{attrs:{"bottom":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","x-large":""}},on),[_c(VAvatar,{attrs:{"color":"primary_medium","size":"48"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.initials))])])],1)]}}],null,false,686579620)},[_c(VCard,[_c(VListItemContent,{staticClass:"justify-center"},[_c('div',{staticClass:"avatar-wrapper d-flex justify-center align-center px-5"},[_c('div',{staticClass:"avatar-icon"},[_c(VAvatar,{attrs:{"color":"primary_medium"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.initials))])])],1),_c('div',{staticClass:"\n                    avatar-content\n                    d-flex\n                    flex-column\n                    align-self-center\n                    flex-grow-1\n                    ml-2\n                  "},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.fullName))]),_c('p',{staticClass:"text-caption ma-0"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])])])]),_c('div',{staticClass:"menu-nav"},[_c('div',[_c(VBtn,{attrs:{"href":"/customer/my-account","plain":"","text":""}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-account-circle")]),_vm._v(" My Account ")],1)],1),_c('div',[_c(VBtn,{attrs:{"href":"/customer/logout","plain":"","text":""}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-door-open")]),_vm._v(" Logout ")],1)],1)])])],1)],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Modal
    v-if="modalActive"
    ref="modal"
    record-icon="run"
    :fullscreen="false"
    :buttons="modalButtons"
    @hidden="modalActive = false"
  >
    <template #title> Create Customer Portal Account </template>
    <div class="content-wrapper">
      <div class="loading-wrapper">
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="false"
        />
      </div>
      <p data-testid="customerError" v-if="showError" class="error-message">
        {{ errorMessage }}
      </p>
      <v-row>
        <FormInput
          ref="emailInput"
          v-model="customerEmail"
          type="email"
          label="Enter your Email Address."
          required
        />
      </v-row>
      <ContactSelectionTable
        :items="foundContacts"
        @update:selected-contact="handleSelectedContact"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/misc/Modal';
import FormInput from '../form-inputs/FormInput';
import Loading from 'vue-loading-overlay';
import ContactSelectionTable from '@/components/customer-portal/ContactSelectionTable';
import functions from '@/mixins/functions';
import axios from 'axios';
import getBaseUrl from '@/services/base-farmgate-url';

const ALREADY_EXISTS_ERROR =
  'It looks like there is already an account set up for this email. Please return to the login screen and select reset password.';

export default {
  name: `CreateAccountInitModal`,
  components: { Modal, FormInput, Loading, ContactSelectionTable },
  mixins: [functions],
  props: { isActive: Boolean },
  data() {
    return {
      modalActive: false,
      customerEmail: '',
      isLoading: false,
      showError: false,
      foundContacts: [],
      selectedContact: null,
      validInput: false,
      errorMessage: `
                Whoops... we could not find your info in our system.  
                Please reach out to your Precision Agronomist for assistance
            `,
    };
  },

  computed: {
    modalButtons() {
      if (!this.foundContacts.length) {
        return {
          Close: { color: `light`, click: this.handleCloseClick },
          Next: { color: `primary`, click: this.handleNextClick },
          //Continue: this.foundContacts.length ? false : { color: `primary`, click: this.handleContinueClick }
        };
      }
      return {
        Close: { color: `light`, click: this.handleCloseClick },
        Continue: {
          color: `primary`,
          click: this.handleContinueClick,
          disabled: this.continueDisabled,
        },
      };
    },
    continueDisabled() {
      return !this.selectedContact;
    },
  },

  watch: {
    isActive: {
      handler(newVal, oldVal) {
        this.modalActive = newVal;
      },
    },
  },

  methods: {
    handleCloseClick() {
      this.modalActive = false;
      this.showError = false;
      this.customerEmail = '';
    },
    handleContinueClick() {
      this.$router.push({
        path: `/customer/create-account/${this.selectedContact.id}/${this.selectedContact.uuid}`,
      });
    },
    async handleNextClick() {
      this.showError = false;
      this.isLoading = true;
      axios
        .get(
          `${getBaseUrl()}customers/verify?customerEmail=${encodeURIComponent(
            this.customerEmail
          )}`
        )
        .then((resp) => {
          this.isLoading = false;

          if (resp.data.customerExists) {
            this.errorMessage = ALREADY_EXISTS_ERROR;
            this.showError = true;
            return;
          }

          if (resp.status !== 200 || !resp.data.length) {
            this.showError = true;
            return;
          }

          this.foundContacts = resp.data;
        })
        .catch((e) => {
          this.isLoading = false;
          this.showError = true;
        });
    },
    handleSelectedContact(contact) {
      this.selectedContact = contact[0];
    },
  },
};
</script>
<style lang="scss" scoped>
.error-message {
  color: red;
  font-weight: bold;
}
.content-wrapper {
  position: relative;

  .loading-wrapper {
    position: absolute;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #fff;
  }
}
</style>

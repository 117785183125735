<template>
  <tr
    :class="{ gray: highlightedRow == index }"
    style="cursor: pointer"
    @click="$emit('accountClick', item)"
  >
    <td><Highlighter :value="item.contactName" :highlight="input" /></td>
    <td>
      <Highlighter :value="item.operations.join(', ')" :highlight="input" />
    </td>
    <td><Highlighter :value="item.accountNumber" :highlight="input" /></td>
    <td><Highlighter :value="item.agronomistName" :highlight="input" /></td>
    <td :class="`d-flex flex-row align-center justify-end`">
      {{ formatBalance(item.openBalance) }}
    </td>
  </tr>
</template>

<script>
import { formatBalance } from '@/utils/formatters';
import Highlighter from '@/components/Highlighter';

export default {
  name: 'AccountSearchResults',

  components: {
    Highlighter,
  },

  props: { input: String, item: Object, index: Number, highlightedRow: Number },

  methods: {
    formatBalance,
  },
};
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.inPlace ? "div" : "VDialog",{tag:"component",attrs:{"fullscreen":_vm.fullscreen,"transition":"dialog-bottom-transition","persistent":_vm.fullscreen || _vm.loading,"width":_vm.fullscreen ? null : _vm.width},on:{"click:outside":function($event){return _vm.$emit('click:outside')}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VForm,{attrs:{"disabled":_vm.disabled || _vm.loading},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"d-flex flex-column",style:(_vm.fullscreen && { height: "100vh" }),attrs:{"color":"white"}},[(_vm.fullscreen)?_c(VToolbar,{staticClass:"flex-grow-0",staticStyle:{"z-index":"2"},attrs:{"color":_vm.pageToolbar ? "" : "primary","rounded":"0","short":""}},[_c(VToolbarTitle,{class:{
            'white--text': !_vm.pageToolbar,
            'd-flex align-center': _vm.fullscreen,
          }},[(_vm.recordIcon)?_c('RecordIcon',{staticClass:"ml-0 mr-3",attrs:{"record":_vm.recordIcon,"avatar-props":{ size: 36 }}}):_vm._e(),_c('div',{staticClass:"text-overline"},[_vm._v(_vm._s(_vm.superTitle))]),_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})],2),_c(VSpacer),_vm._t("toolbar"),(!_vm.unclosable)?_c(VBtn,{attrs:{"dark":!_vm.pageToolbar,"icon":"","disabled":_vm.loading},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("$close")])],1):_vm._e()],2):_c(VCardTitle,{staticClass:"px-6"},[(_vm.recordIcon)?_c('RecordIcon',{staticClass:"mr-3",attrs:{"record":_vm.recordIcon,"avatar-props":{ size: 36 },"icon-props":{ size: 22 }}}):_vm._e(),_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]}),(_vm.inPlace)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("$close")])],1):_vm._e()],2),_c(VContainer,{staticClass:"pt-4 overflow-x-auto flex-grow-1 fill-height align-start",class:{ 'px-6': !_vm.fullscreen },attrs:{"fluid":_vm.fluid}},[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"100%"},style:({ maxWidth: (_vm.width + "px") })},[_vm._t("default")],2)]),(_vm.buttons)?_c(VFooter,{staticClass:"justify-end px-6 py-4",staticStyle:{"background":"#fff","padding":"1rem","z-index":"1"},attrs:{"elevation":_vm.fullscreen ? 7 : 0,"rounded":"0"}},[_vm._t("footer"),_vm._l((_vm.buttons),function(button,label,i){return [(button)?_c(VBtn,_vm._b({key:label,class:button.class || ("m" + (i > _vm.spacerIndex ? "l" : "r") + "-4"),attrs:{"data-testid":"modalActions","color":button.color,"height":button.height,"loading":(button.submitter || i + 1 == _vm.buttonsArr.length) && _vm.loading,"outlined":button.outlined,"disabled":button.disabled ||
              ((i + 1 == _vm.buttonsArr.length || button.submitter) && !_vm.valid) ||
              (!(i + 1 == _vm.buttonsArr.length || button.submitter) && _vm.loading),"type":button.submitter || i + 1 == _vm.buttonsArr.length
                ? "submit"
                : undefined},on:{"click":function($event){return _vm.clickButton($event, button)}}},'v-btn',button.props,false),[(button.icon && i < _vm.spacerIndex)?_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(button.icon))]):_vm._e(),_vm._v(" "+_vm._s(label)+" "),(button.icon && i > _vm.spacerIndex)?_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-"+_vm._s(button.icon))]):_vm._e()],1):_c(VSpacer,{key:label})]})],2):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
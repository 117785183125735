import Vue from 'vue';
import functions from '@/mixins/functions';
import Auth from '@aws-amplify/auth';

// noinspection JSUnusedGlobalSymbols
const { methods } = functions,
  tables = require(`@/assets/tables.json`),
  state = {
    // { table: [ ids and uuids ]} of records being fetched individually so repeat requests aren't made
    fetching: Object.fromEntries(tables.map((t) => [t, []])),
    page: [
      {
        id: 37,
        parent_id: 0,
        page_title: 'Customer Portal',
        icon: '',
        link_url: '/customer/customer-portal',
        has_badge: 0,
        badge_column: null,
      },
      {
        id: 38,
        parent_id: 37,
        page_title: 'Home',
        icon: 'home',
        link_url: '/customer',
        has_badge: 0,
        badge_column: null,
      },
      {
        id: 39,
        parent_id: 37,
        page_title: 'Agronomy',
        icon: 'map',
        link_url: '/customer/agronomy',
        has_badge: 1,
        badge_column: 'total_new_scouting_reports',
      },
      {
        id: 40,
        parent_id: 37,
        page_title: 'Contracts',
        icon: 'script-text-outline',
        link_url: '/customer/contracts',
        has_badge: 1,
        badge_column: null,
      },
      {
        id: 42,
        parent_id: 37,
        page_title: 'My Account',
        icon: 'account-box',
        link_url: '/customer/my-account',
        has_badge: 0,
        badge_column: null,
      },
      {
        id: 52,
        parent_id: 37,
        page_title: 'Invoices & Billing',
        icon: 'receipt',
        link_url: '/customer/invoices-billing',
        has_badge: 1,
        badge_column: 'total_open_invoices',
      },
    ],
    //me: null
  },
  getters = {
    getUserChain:
      (state, { UserById }) =>
      (agronomist) =>
        [`Area Business Manager`, `Regional Business Director`]
          .map((label) => ({
            user: UserById(agronomist[label.toLowerCase().replace(/ /g, `_`)]),
            label,
          }))
          /* Handle the case where `agronomist` was an admin or readonly person - those kinds of people don't have an
						 ABM or RBD */
          .filter(({ user }) => user),
    servicesHierarchy({ service }) {
      const servicesByParentId = (parentId) =>
        service.filter(({ parent_id }) => parent_id == parentId);
      return servicesByParentId(0).map((service) => ({
        ...service,
        children: servicesByParentId(service.id),
      }));
    },
    transactionProductServices: (state) =>
      state.transaction_product_service.map((product_service) => ({
        name: `${product_service.name}`,
        product_service_id: product_service.id,
      })),
    getTransactionProductService:
      (state, { transactionProductServices }) =>
      (invoice) =>
        transactionProductServices.find(
          (rec) => rec.product_service_id === invoice.product_service_id
        ),
    USER_GROUP: () => ({
      CUSTOMER: 1,
      RBD: 2,
      ABM: 3,
      PA: 4,
      ADMIN: 5,
      ACCOUNTING: 6,
      ASL: 7,
      NonAcctAdmin: 8,
    }),
    INVOICE_METHOD: ({ invoice_method }) =>
      Object.fromEntries(
        invoice_method.map(({ id, name }) => [name.toUpperCase(), id])
      ),
    myAgronomists: (state, getters, _, rootGetters) => {
      const { PA, ASL } = getters.USER_GROUP;
      return state.user.filter(
        (user) =>
          user.status === 1 &&
          [PA, ASL].includes(user.user_group_id) &&
          user.area_business_manager === rootGetters['auth/me'].id
      );
    },
    getASLsAndRBDs: (state, getters) =>
      state.user.filter(
        (user) =>
          user.status === 1 &&
          (user.user_group_id === getters.USER_GROUP.RBD ||
            user.user_group_id === getters.USER_GROUP.ASL)
      ),
    getServerUrl: (state) =>
      state.environment.find((environment) => environment.name === 'serverUrl')
        .value,
  },
  mutations = {
    pushFetching(state, { table, id }) {
      state.fetching[table].push(id);
    },
    removeFetching(state, { table, id }) {
      const fetch = state.fetching[table],
        i = fetch.indexOf(id);
      if (~i) fetch.splice(i, 1);
    },
    setConfig(state, config) {
      Object.assign(state, config);
    },
    updateWorkingRelationships(state, userPartners) {
      state.user_partner = [...userPartners];
    },
  },
  actions = {
    async fetchConfig({ commit }) {
      let response;
      try {
        response = await methods.crmRequest(`config`, {}, `GET`);
      } catch (e) {
        if (e.status == 401) {
          Auth.signOut();
          return;
        }
      }

      return commit(`setConfig`, response.value);
    },
    async setConfig({ dispatch, commit }, value) {
      return commit('updateWorkingRelationships', value.user_partner);
    },
  };

// this generates state, getters, mutations, and actions for all tables in the json file for local stuff
tables.forEach((table) => {
  const pascal = methods.toPascalCase(table);
  state[table] = [];

  getters[`${pascal}ById`] = (state) => (id) =>
    state[table].find((val) => val.id == id);

  Object.assign(mutations, {
    [`save${pascal}s`](state, passedValues) {
      passedValues.forEach((passedValue) => {
        const thisState = state[table],
          i = thisState.findIndex(
            ({ uuid }) => uuid && uuid === passedValue.uuid
          );
        if (~i) Vue.set(thisState, i, passedValue);
        else thisState.push(passedValue);
      });
    },
    [`delete${pascal}s`](state) {
      state[table] = [];
    },
    [`delete${pascal}ById`](state, deleteId) {
      const i = state[table].findIndex(({ id }) => id && id === deleteId);
      if (~i) state[table].splice(i, 1);
    },
  });

  Object.assign(actions, {
    [`get${pascal}Count`]: async (_, { filters }) =>
      (
        await methods.crmRequest(
          `crud/${table.replace(/_/g, `-`)}`,
          { filters, count: true },
          `GET`
        )
      ).value[0].count,
    [`get${pascal}FilterValues`]: async (
      _,
      { filters, selectedFilterOptions }
    ) =>
      (
        await methods.crmRequest(
          `crud/${table.replace(/_/g, `-`)}`,
          { filters, selectedFilterOptions, getFilters: true },
          `GET`
        )
      ).value,
    async [`get${pascal}s`](
      { commit },
      { columns, itemsPerPage, page, sortBy, sortDesc, filters } = {}
    ) {
      if (!page) page = 1;
      const { value } = await methods.crmRequest(
        `crud/${table.replace(/_/g, `-`)}`,
        {
          filters,
          amount: itemsPerPage || 25,
          sortBy,
          sortDesc,
          page,
          ...(columns && { columns }),
          ...(itemsPerPage && { offset: itemsPerPage * (page - 1) }),
        },
        `GET`
      );
      commit(`save${pascal}s`, value);
      return value;
    },
    async [`get${pascal}ById`]({ commit, state }, { id, refresh }) {
      if (!refresh) {
        const match = state[table].find((val) => id && val.id === id);
        if (match) return match;
      }
      if (state.fetching[table].includes(id)) return;
      const fetchingObj = { table, id };
      commit(`pushFetching`, fetchingObj);
      const { value } = await methods.crmRequest(
        `crud/${table.replace(/_/g, `-`)}/${id}`,
        null,
        `GET`
      );
      commit(`removeFetching`, fetchingObj);
      commit(`save${pascal}s`, value);
      return value.length ? value[0] : {};
    },
  });
});

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAvatar,{attrs:{"color":_vm.loading ? null : "#285817","size":_vm.size,"dark":""}},[(_vm.loading)?_c(VSkeletonLoader,{attrs:{"type":"avatar"}}):(_vm.imgSrc)?_c(VImg,{attrs:{"src":_vm.imgSrc && ~_vm.imgSrc.indexOf("https") ? _vm.imgSrc : _vm.getS3PictureUrl(_vm.imgSrc)}}):_c('span',{staticClass:"white--text",style:({ fontSize: ((Math.min(_vm.size / 2, 13)) + "px") })},[_vm._v(" "+_vm._s(_vm.name .toUpperCase() .split(" ") .slice(0, 2) .map(function (ref) {
	var l = ref[0];

	return l;
}) .join(""))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-skeleton-loader loading type="table" />
</template>

<script>
import { mapGetters } from 'vuex';
import functions from '@/mixins/functions';
import { ABM, ADMIN, ASL, PRECISION_AGRONOMIST } from '@/utils/constants';

export default {
  name: 'DefaultView',

  mixins: [functions],

  mounted() {
    if (this.$router.currentRoute.path === '/') {
      const basePath = this.getStartingPath();

      this.$router.push(basePath);
    }
  },

  computed: {
    ...mapGetters('auth', ['me']),
  },

  methods: {
    getStartingPath() {
      if (this.IS_CUSTOMER_PORTAL) {
        return '/customer';
      } else if (this.isDashboardEnabled()) {
        return '/dashboard';
      } else {
        return '/targets';
      }
    },
    isDashboardEnabled() {
      return [PRECISION_AGRONOMIST, ASL, ABM].includes(this.me.user_group_id);
    },
  },
};
</script>

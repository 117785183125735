import { get } from '../services/api-service';

export default {
  namespaced: true,

  state: {
    loading: false,
    accounts: [],
  },

  getters: {
    accounts: ({ accounts }) => {
      return accounts;
    },
  },

  actions: {
    async loadAccounts({ commit, dispatch }) {
      commit('updateAccounts', { loading: true, accounts: [] });

      try {
        const accounts = await get('accounts');

        commit('updateAccounts', { loading: false, accounts });
      } catch (e) {
        dispatch('app/showError', 'Error retrieving accounts data', {
          root: true,
        });
        commit('updateAccounts', { loading: false });
      }
    },
  },

  mutations: {
    updateAccounts(state, payload) {
      Object.assign(state, payload);
    },
  },
};
